import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PatientService } from '../../services';
import jspdf from 'jspdf';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {
  patient_number: any;
  riskData: any;
  pId: any;
  averageTurnFrequency:Array<any> = [];
  staticPosition:Array<any> = [];
  currentDate: any;
  donutData: any;
  pdfData:any;
  constructor(
    public patientService: PatientService,
  ) { }

  ngOnInit(): void {
    this.patient_number = localStorage.getItem('pNumber') || '';
    this.currentDate = new Date();
    
  }

  convertToPDF(): any {
    const node = document.getElementById('contentToConvert');
    let img;
    let filename;
    let newImage;
    domtoimage
      .toPng(node, { bgcolor: '#fff' , transform: 'scale(2,2)'})
      .then((dataUrl) => {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
 
        img.onload = () => {
          const pdfWidth = img.width;
          const pdfHeight = img.height;
          let doc;
 
          if (pdfWidth > pdfHeight) {
            doc = new jspdf('l', 'px', [pdfWidth, pdfHeight]);
          } else {
            doc = new jspdf('p', 'px', [pdfWidth, pdfHeight]);
          }
 
          const width = doc.internal.pageSize.getWidth();
          const height = doc.internal.pageSize.getHeight();
 
          doc.addImage(newImage, 'PNG', 20, 20, width, height);
          filename = 'patient_' +this.patient_number+ '_report' + '.pdf';
          doc.save(filename);
        };
      })
      .catch((error) => {
        // Error Handling
      });
  }

}
