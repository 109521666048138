<div class="top-nav" style="background:#fafafa;margin-top:2px;">
    <div fxLayout="row wrap" class="pad-10 ">
        <p fxFlex="25%" class="pad-left-20 pad-top-20 lenexa-heading">{{"connector.journey" | translate}}</p>
        <div fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="50%">
            <app-lenexa-stepper [steps]="riskSteps" *ngIf='pNumber==""  || !hideriskSteps'></app-lenexa-stepper>
        </div>
    </div>
    <hr class="stepper-separator" />
</div>
<div class="main-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div class="connect-patient-container step1-patient-container" fxLayout="row" *ngIf="connectionSteps">
        <!--smart sheet component-->
        <div class="lenexa-smart-sheet-container" fxLayout="column" fxLayoutAlign="center center">
            <p>{{'connector.smartsheet' | translate}}</p>
            <div class="lenexa-smart-sheet-wrapper">
                <img class="lenexa-smart-sheet-close-button pointer" src="./assets/images/connection-close-icon.svg"
                    height="30" width="30" alt="closeButton" />
                <div class="lenexa-smart-sheet-img" fxFlexFill fxLayoutAlign="center center">
                    <div fxLayout="row" fxflex="100%" style="position:relative">
                        <mat-card-actions fxLayout="column" class="w-100" fxFlex="120%">
                            <!-- <button mat-button class="lenexa-btn lenexa-btn-primary pointer add-patient-btn"
                                (click)="onAddPatientClick()">
                                {{'connector.addPatient' | translate}}
                            </button> -->
                            <button mat-button class="lenexa-btn lenexa-btn-primary pointer add-patient-btn" (click)="onAddPatientClick()">
                                Add Bed
                            </button>
                            <div fxLayout="column">
                                <img src="./assets/images/connection_arrowplus.svg" class="pad-top-30 lenexa-logo"
                                    height="30" alt="connector" />
                            </div>
                        </mat-card-actions>
                    </div>
                </div>
                <img class="sensor-cable" src="./assets/images/sensor-cable.svg" alt="sensor cable img">
                <img class="power-cable" src="./assets/images/power-cable.svg" alt="power cable img">
            </div>
        </div>
        <!--connector-->
        <div fxLayout="column" fxLayoutAlign="center center">
            <p class="connection-upper-text" *ngIf="step1">{{'connector.poweroutlettext' | translate}}</p>
            <img src="./assets/images/power-outlet.svg" *ngIf="step1" class="power-outlet-socket-img" height="10"
                alt="connector" />
            <img src="./assets/images/circle.svg" *ngIf="!step1" class="power-outlet-socket-img circle" height="10"
                alt="connector" />
            <img src="./assets/images/polygon.svg" *ngIf="!step1" class="power-outlet-socket-img polygon" height="10"
                alt="connector" />
        </div>
        <!--hub component-->
        <div class="lenexa-hub-container" fxLayout="column" fxLayoutAlign="center center"
            [ngStyle]="{'margin-top': !step1 ? '60px' :''}">
            <p>{{'connector.lenexahub' | translate}}</p>
            <div class="lenexa-hub-wrapper">
                <!-- <img class="lenexa-hub-close-button pointer" src="./assets/images/connection-close-icon.svg" height="30"
                    width="30" alt="closeButton" /> -->
                <div fxLayout="row" class="lenexa-hub-content-wrapper">
                    <div fxLayout="column" fxFlex="80" fxLayoutAlign="start start">
                        <img src="./assets/images/lenexa-logo.png" class="hub-logo" alt="logo" />
                        <div fxLayout="row" class="buttons-wrapper">
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <span class="power-text" *ngIf="step2">White</span>
                                <div class="margin-auto lenexa-hub-connection hub-off-status"
                                    [ngClass]="{'step2-border':step2}"></div>
                                <p> {{'connector.power' | translate}}</p>
                                <div class='status-bar power-status-bar'></div>
                            </div>
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <span class="sensor-text" *ngIf="step2">White</span>
                                <div class="margin-auto lenexa-hub-connection hub-off-status"
                                    [ngClass]="{'step2-border':step2}"></div>
                                <p> {{'connector.sensor' | translate}}</p>
                                <div class='status-bar sensor-status-bar'></div>
                            </div>
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10"
                                [ngClass]="{'hub-magnifying':step3}">
                                <span class="data-text" *ngIf="step2">Amber</span>
                                <div class="margin-auto lenexa-hub-connection hub-amber-status"
                                    [ngClass]="{'step2-border':step2}"></div>
                                <p> {{'connector.data' | translate}}</p>
                                <div class='status-bar data-status-bar'></div>
                                <img class="dash-line"
                                    [ngClass]="{'step2-dash':step2,'substep2-dash':subStep2,'step3-dash':step3,'step4-dash':step4,'step1-dash':step1}"
                                    src="./assets/images/dashline.svg" alt="dashline img">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between end">
                        <div fxLayout="row">
                            <div fxLayout="column">
                                <p class="connector-hub-icon-text">{{'connector.hub' | translate}}</p>
                                <p class="connector-hub-id">xxxx</p>
                                <img class="hubiconcircle" *ngIf="subStep2 || step4"
                                    src="./assets/images/hubiconcircle.svg" alt="hub icon circle img">
                                <img class="hubicon" *ngIf="subStep2 || step4" src="./assets/images/hubicon.svg"
                                    alt="hub icon img">
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img class="power-socket" src="./assets/images/power-socket.svg" alt="power socket img"
                        *ngIf="step1">
                    <img class="power-pluggedin" src="./assets/images/socket-pluggedin.svg" alt="power socket img"
                        *ngIf="!step1">
                    <img class="sensor-socket" src="./assets/images/sensor-socket.svg" alt="sensor socket img"
                        *ngIf="step1">
                    <img class="sensor-socket-pluggedin" src="./assets/images/sensor-socket-pluggedin.svg"
                        alt="sensor socket img" *ngIf="!step1">
                    <img class="data-socket" src="./assets/images/data-socket.svg" alt="data socket img" *ngIf="step1">
                    <img class="data-socket-pluggedin" src="./assets/images/data-cable-pluggedin.svg"
                        alt="data socket img" *ngIf="!step1">
                    <img class="data-cable" src="./assets/images/data-cable.svg" alt="data cable img">
                </div>
            </div>
        </div>
        <!--pairHub connector-->
        <div fxLayout="column" fxLayoutAlign="center center" class="lenexa-ethernet-container">
            <p class="connection-upper-text" [ngClass]="{'hidden':!step1}">{{'connector.ethernettext' | translate}}</p>
            <img src="./assets/images/ethernet-port.svg" *ngIf="!subStep2" alt="pairHub" height="10"
                class="power-outlet-socket-img" />
            <img src="./assets/images/ethernet-connected.svg" *ngIf="subStep2" alt="pairHub" height="10"
                class="power-outlet-socket-img ethernet-connected-socket" />
        </div>
        <!--this display component-->
        <div class="lenexa-action-container" fxLayout="column" fxLayoutAlign="center center" *ngIf="step1">
            <div class="lenexa-action-content step1-action-content">
                <p>{{'connector.actionstep1description' | translate}}</p>
                <button mat-button class="lenexa-btn lenexa-btn-primary pointer" style="min-width: 10vw;height: 38px;border-radius: 20px;" (click)="cableConnected()">
                    {{'connector.cableconnected' | translate}}
                </button>
                <a class="need-help" href="javascript:void(0);">{{'connector.needhelp' |
                    translate}}</a><!--(click)="faultDialog()"-->
            </div>
        </div>
        <div class="lenexa-action-container step-container" fxLayout="column" fxLayoutAlign="center center"
            *ngIf="step2">
            <div class="lenexa-action-content" [ngStyle]="{'margin-top': step2 ? '85px' :''}">
                <p class="description">{{'connector.actionstep2description' | translate}}</p>
                <button mat-button class="lenexa-btn lenexa-btn-secondary pointer back-btn" (click)="step2Back()">
                    {{'connector.back' | translate}}
                </button>
                <button mat-button class="lenexa-btn lenexa-btn-primary pointer light-match-btn"
                    (click)="lightsMatch()">
                    {{'connector.lightsmatch' | translate}}
                </button>
                <a class="need-help" href="javascript:void(0);">{{'connector.needhelp' | translate}}</a>
            </div>
        </div>
        <div class="lenexa-action-container step-container step3-container sub-step2-container" fxLayout="column"
            fxLayoutAlign="center center" *ngIf="subStep2">
            <div class="lenexa-action-content">
                <p class="description" style="font-size: 17px">{{'connector.actionsubstep2description1' | translate}}
                    <u>on your Hub</u> <b> for 5 SECONDS.</b>
                    {{'connector.actionsubstep2description2' | translate}} <span class="amber-color">{{'connector.amber'
                        |
                        translate}}</span>{{'connector.actionsubstep2description3' | translate}}
                </p>
                <button mat-button class="lenexa-btn lenexa-btn-secondary pointer back-btn" (click)="subStep2Back()">
                    {{'connector.back' | translate}}
                </button>
                <button mat-button class="lenexa-btn lenexa-btn-primary pointer light-match-btn" 
                    (click)="subLightsMatch()">
                    {{'connector.next' | translate}}
                </button>
                <a class="need-help" href="javascript:void(0);">{{'connector.needhelp' | translate}}</a>
            </div>
        </div>
        <div class="lenexa-action-container step-container step3-container" fxLayout="column"
            fxLayoutAlign="center center" *ngIf="step3">
            <div class="lenexa-action-content">
                <p class="description">{{'connector.actionstep3description' | translate}} <span
                        class="amber-color">{{'connector.amber' | translate}}</span>?</p>
                <button mat-button class="lenexa-btn lenexa-btn-secondary pointer back-btn" (click)="step3Back()">
                    {{'connector.back' | translate}}
                </button>
                <button mat-button class="lenexa-btn lenexa-btn-primary pointer light-match-btn" (click)="flashing()">
                    {{'connector.lightsflash' | translate}}
                </button>
                <a class="need-help" href="javascript:void(0);">{{'connector.needhelp' | translate}}</a>
            </div>
        </div>
        <div class="lenexa-action-container step-container" fxLayout="column" fxLayoutAlign="center center"
            *ngIf="step4">
            <div class="lenexa-action-content">
                <p class="description" style="font-size: 16px;
                width: 400px;">{{'connector.hubiddescription' | translate}} <span
                        class="enter-hub">{{'connector.enterhubiddescription' | translate}}</span></p>
                <div fxLayout="row wrap" class="hub-input-container">
                    <form class="hub-form" [ngClass]="{ 'error-hub': errorMessage }">
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                            <input type="text" maxlength="1" [(ngModel)]="hubIdInput1" (keyup)="onDigitInput($event)"
                                name="hubIdInput1" (keypress)="keyPress(hubIdInput1)" />
                            <input type="text" maxlength="1" [(ngModel)]="hubIdInput2" (keyup)="onDigitInput($event)"
                                name="hubIdInput2" (keypress)="keyPress(hubIdInput2)" />
                            <input type="text" maxlength="1" [(ngModel)]="hubIdInput3" (keyup)="onDigitInput($event)"
                                name="hubIdInput3" (keypress)="keyPress(hubIdInput3)" />
                            <input type="text" maxlength="1" [(ngModel)]="hubIdInput4" (keyup)="onDigitInput($event)"
                                name="hubIdInput4" (keypress)="keyPress(hubIdInput4)" />
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="errorMessage" class="error-message">
                            {{ "connector.huberrormessage" | translate }}
                        </div>
                    </form>
                </div>
                <button mat-button class="lenexa-btn lenexa-btn-secondary pointer back-btn" (click)="step4Back()">
                    {{'connector.back' | translate}}
                </button>
                <button mat-button class="lenexa-btn lenexa-btn-primary pointer light-match-btn"  [disabled]="
                !hubIdInput1 ||
                !hubIdInput2 ||
                !hubIdInput3 ||
                !hubIdInput4
              " (click)="connectorDone()">
                    {{'connector.done' | translate}}
                </button>
                <a class="need-help" href="javascript:void(0);">{{'connector.needhelp' | translate}}</a>
            </div>
        </div>
    </div>
    <!-- <div class="connect-patient-container" fxLayout="row" *ngIf="!connectionSteps" style="margin-top:3px !important">
        <div class="lenexa-smart-sheet-container" fxLayout="column" fxLayoutAlign="center center">
            <p>{{'connector.smartsheet' | translate}}</p>
            <div class="lenexa-smart-sheet-wrapper">
                <img class="lenexa-smart-sheet-close-button pointer" src="./assets/images/connection-close-icon.svg"
                    height="30" width="30" alt="closeButton" (click)="closeSheetDialog()" *ngIf=" pNumber != '' " />
                <div class="lenexa-smart-sheet-img" fxFlexFill fxLayoutAlign="center center" *ngIf=" pNumber == '' ">
                    <div fxLayout="row" fxflex="100%" style="position:relative">
                        <mat-card-actions fxLayout="column" class="w-100" fxFlex="120%">
                            <button mat-button class="lenexa-btn lenexa-btn-primary pointer add-patient-btn"  (click)="onAddPatientClick()">
                                Add Bed
                            </button>
                            <div fxLayout="column">
                                <img src="./assets/images/connection_arrowplus.svg" class="pad-top-30 lenexa-logo"
                                    height="30" alt="connector" />
                            </div>
                        </mat-card-actions>
                    </div>
                </div>
                <div class="lenexa-smart-sheet-img" fxFlexFill *ngIf=" pNumber != '' ">
                    <div fxLayout="row" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="15%" fxFlex.xs="10%" fxFlex.sm="10%">
                            <img src="assets\images\user_icon.png" class="user-info pad-right-30" height="30"
                                width="30" />
                        </div>
                        <div fxFlex="80%" fxFlex.xs="55%" fxFlex.sm="55%" style="font-size: 12px"
                            class="patient-record-number pad-left-30">
                            <div class="main-text">{{ "dashboard.patientnumber" | translate }}</div>
                            <div class="sub-text">
                                <p>{{pNumber}}</p>
                            </div>
                        </div>
                    </div>
                    <div fxLayoutAlign="center center"> <img src="/assets/images/heatmap.png" height="300px;"></div>
                    <div fxLayout="row" fxflex="100%" style="position:relative">
                        <div fxLayout="column">
                            <img src="./assets/images/connection_arrowplus.svg" class="pad-top-30 lenexa-arrowplus"
                                height="30" alt="connector" />
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxflex="100%" style="position:relative" class="pad-top-10" *ngIf="pNumber">
                <mat-card-actions fxLayout="column" class="w-100" fxFlex="120%">
                    <button mat-button class="lenexa-btn lenexa-btn-primary pointer" (click)="navigateToDashboard()">
                        {{'connector.viewDashboard' | translate}}
                    </button>
                </mat-card-actions>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
            <img src="./assets/images/connector-icon.svg" class="pad-top-30" height="10" alt="connector" />
        </div>
        <div class="lenexa-hub-container" fxLayout="column" fxLayoutAlign="center center">
            <p>{{'connector.lenexahub' | translate}}</p>
            <div class="lenexa-hub-wrapper">
                <img class="lenexa-hub-close-button pointer" src="./assets/images/connection-close-icon.svg" height="30"
                    width="30" alt="closeButton" (click)="closeHubDialog()" />
                <div fxLayout="row" class="lenexa-hub-content-wrapper">
                    <div fxLayout="column" fxFlex="80" fxLayoutAlign="start start">
                        <img src="./assets/images/lenexa-logo.png" alt="logo" class="hub-logo" />
                        <div fxLayout="row" class="buttons-wrapper">
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <i class="fa fa-power-off lenexa-hub-icon"></i>
                                <p> {{'connector.power' | translate}}</p>
                                <div class='status-bar power-status-bar'></div>
                            </div>
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <div class="margin-auto lenexa-hub-connection hub-off-status"></div>
                                <p> {{'connector.sensor' | translate}}</p>
                                <div class='status-bar sensor-status-bar'></div>
                            </div>
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <div class="margin-auto lenexa-hub-connection hub-on-status"></div>
                                <p> {{'connector.data' | translate}}</p>
                                <div class='status-bar data-status-bar'></div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between end">
                        <div fxLayout="row">
                            <div fxLayout="column">
                                <p class="connector-hub-icon-text">{{'connector.hub' | translate}}</p>
                                <p class="connector-hub-id">xxxx</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hub-description" [ngStyle]="{'bottom': hub_description.length > 30 ? '-70px' :'-56px'}">
                    <div class="hub-id">Hub ID - {{hub_id}}</div>
                    <div><span class="hub-description-text" *ngIf="hub_description !=''">'{{hub_description}}'</span>
                        <img class="edit-image" src="assets/images/edit-icon.svg" alt="edit"
                            (click)="editHubDescription()">
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="paired-successfully" *ngIf="pairedSuccessfully">
                <span class="content">{{'connector.pairedsuccessfully' | translate}}</span>
                <img src="./assets/images/horizontaldashline.svg" alt="dash line" class="horizontal-dashline" />
            </div>
            <img src="./assets/images/connector-icon.svg" alt="pairHub" height="10" class="pad-top-30"
                *ngIf="!isHubDisconnected" />
            <img src="./assets/images/disconnect_hub_icon.svg" alt="pairHub" height="10" *ngIf="isHubDisconnected"
                style="padding-top:160px;" />
            <img src="./assets/images/horizontaldashline.svg" alt="dash line" class="horizontal-dashline"
                *ngIf="isHubDisconnected" style="height: 130px;
            width: 100px;margin-left:2.5px;" />
        </div>
        <div class="lenexa-display-container" fxLayout="column" fxLayoutAlign="center center">
            <p>{{'connector.display' | translate}}</p>
            <div class="lenexa-display">
                <div></div>
            </div>
            <div class="rectangle" id="rectangle">.</div>
            <div class="lenexa-set-description" *ngIf="step5">
                <img class="step5-dashline" src="./assets/images/dashline.svg" alt="dashline img"
                    *ngIf="isHubDisconnected !== true">
                <div class="lenexa-action-content" *ngIf="isHubDisconnected !== true">
                    <p class="set-description-heading">{{'connector.setdescriptionheading' | translate}}</p>
                    <p class="sub-heading">Set Description <span
                            class="heading-light">{{'connector.setdescriptionsubheading' | translate}}</span></p>
                    <div fxLayout="row wrap" class="hub-input-container">
                        <form class="hub-form">
                            <div fxLayout="row" fxLayoutAlign="space-around center">
                                <input class="hub-form-input" type="text" maxlength="50" [(ngModel)]="setDescription"
                                    name="setDescription"
                                    placeholder="{{'connector.setdescriptionplaceholder' | translate}}" />
                            </div>
                        </form>
                    </div>
                    <button mat-button class="lenexa-btn lenexa-btn-secondary pointer" (click)="skipSetDescription()"
                        [ngClass]="{'disabled-class': setDescription}">
                        {{'connector.skip' | translate}}
                    </button>
                    <button mat-button class="lenexa-btn lenexa-btn-primary pointer save-btn" (click)="saveAndNext()">
                        {{'connector.saveandnext' | translate}}
                    </button>
                    <a class="need-help" href="javascript:void(0);">{{'connector.needhelp' | translate}}</a>
                </div>
            </div>
        </div>
    </div> -->
    <div class="connect-patient-container" fxLayout="row" *ngIf="!connectionSteps" style="margin-top:3px !important">
        <!--smart sheet component-->
        <div class="lenexa-smart-sheet-container" fxLayout="column" fxLayoutAlign="center center">
            <p>{{'connector.smartsheet' | translate}}</p>
            <div class="lenexa-smart-sheet-wrapper">
                <img class="lenexa-smart-sheet-close-button pointer" src="./assets/images/connection-close-icon.svg"
                    height="30" width="30" alt="closeButton" (click)="closeSheetDialog()" *ngIf=" bedNumber != '' " />
                <div class="lenexa-smart-sheet-img" fxFlexFill fxLayoutAlign="center center" *ngIf=" bedNumber == '' ">
                    <div fxLayout="row" fxflex="100%" style="position:relative">
                        <mat-card-actions fxLayout="column" class="w-100" fxFlex="120%">
                            <!-- <button mat-button class="lenexa-btn lenexa-btn-primary pointer"
                                (click)="onAddPatientClick()">
                                {{'connector.addPatient' | translate}}
                            </button> -->
                            <button mat-button class="lenexa-btn lenexa-btn-primary pointer add-patient-btn" style="min-width: 10vw;height: 38px;border-radius: 20px;"  (click)="onAddPatientClick()">
                                Add Bed
                            </button>
                            <div fxLayout="column">
                                <img src="./assets/images/connection_arrowplus.svg" class="pad-top-30 lenexa-logo"
                                    height="30" alt="connector" />
                            </div>
                        </mat-card-actions>
                    </div>
                </div>
                <div class="lenexa-smart-sheet-img" fxFlexFill *ngIf=" bedNumber != '' ">
                    <div fxLayout="row" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="15%" fxFlex.xs="10%" fxFlex.sm="10%">
                            <img src="assets\images\user_icon.png" class="user-info pad-right-30" height="30"
                                width="30" />
                        </div>
                        <div fxFlex="80%" fxFlex.xs="55%" fxFlex.sm="55%" style="font-size: 12px"
                            class="patient-record-number pad-left-30">
                            <div class="main-text">Ward-Bed Number:</div>
                            <div class="sub-text">
                                <p>{{wardBednumber}}</p>
                            </div>
                        </div>
                    </div>
                    <div fxLayoutAlign="center center"> <img src="/assets/images/heatmap.png" height="300px;"></div>
                    <div fxLayout="row" fxflex="100%" style="position:relative">
                        <div fxLayout="column">
                            <img src="./assets/images/connection_arrowplus.svg" class="pad-top-30 lenexa-arrowplus"
                                height="30" alt="connector" />
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxflex="100%" style="position:relative" class="pad-top-10" *ngIf="bedNumber">
                <mat-card-actions fxLayout="column" class="w-100" fxFlex="120%">
                    <button mat-button class="lenexa-btn lenexa-btn-primary pointer" style="min-width: 10vw;height: 38px;border-radius: 20px;" (click)="navigateToDashboard()">
                        {{'connector.viewDashboard' | translate}}
                    </button>
                </mat-card-actions>
            </div>
        </div>
        <!--connector-->
        <div fxLayout="column" fxLayoutAlign="center center">
            <img src="./assets/images/connector-icon.svg" class="pad-top-30" height="10" alt="connector" />
        </div>
        <!--hub component-->
        <div class="lenexa-hub-container" fxLayout="column" fxLayoutAlign="center center">
            <p>{{'connector.lenexahub' | translate}}</p>
            <div class="lenexa-hub-wrapper">
                <img class="lenexa-hub-close-button pointer" src="./assets/images/connection-close-icon.svg" height="30"
                    width="30" alt="closeButton" (click)="closeHubDialog()" />
                <div fxLayout="row" class="lenexa-hub-content-wrapper">
                    <div fxLayout="column" fxFlex="80" fxLayoutAlign="start start">
                        <img src="./assets/images/lenexa-logo.png" alt="logo" class="hub-logo" />
                        <div fxLayout="row" class="buttons-wrapper">
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <i class="fa fa-power-off lenexa-hub-icon"></i>
                                <p> {{'connector.power' | translate}}</p>
                                <div class='status-bar power-status-bar'></div>
                            </div>
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <div class="margin-auto lenexa-hub-connection hub-off-status"></div>
                                <p> {{'connector.sensor' | translate}}</p>
                                <div class='status-bar sensor-status-bar'></div>
                            </div>
                            <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center" class="pad-10">
                                <div class="margin-auto lenexa-hub-connection hub-on-status"></div>
                                <p> {{'connector.data' | translate}}</p>
                                <div class='status-bar data-status-bar'></div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between end">
                        <div fxLayout="row">
                            <div fxLayout="column">
                                <p class="connector-hub-icon-text">{{'connector.hub' | translate}}</p>
                                <p class="connector-hub-id">xxxx</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hub-description">
                    <div class="hub-id">Hub ID - {{hub_id}}</div>
                    <div><span class="hub-description-text" *ngIf="hub_description !=''">'{{hub_description}}'</span>
                        <img class="edit-image" src="assets/images/edit-icon.svg" alt="edit"
                            (click)="editHubDescription()">
                    </div>
                </div>
            </div>
        </div>
        <!--pairHub connector-->
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="paired-successfully" *ngIf="pairedSuccessfully">
                <span class="content">{{'connector.pairedsuccessfully' | translate}}</span>
                <img src="./assets/images/horizontaldashline.svg" alt="dash line" class="horizontal-dashline" />
            </div>
            <img src="./assets/images/connector-icon.svg" alt="pairHub" height="10" class="pad-top-30"
                *ngIf="!isHubDisconnected" />
            <img src="./assets/images/disconnect_hub_icon.svg" alt="pairHub" height="10" *ngIf="isHubDisconnected"
                style="padding-top:160px;" />
            <img src="./assets/images/horizontaldashline.svg" alt="dash line" class="horizontal-dashline"
                *ngIf="isHubDisconnected" style="height: 130px;
            width: 100px;margin-left:2.5px;" />
        </div>

        <!--this display component-->
        <div class="lenexa-display-container" fxLayout="column" fxLayoutAlign="center center">
            <p>{{'connector.display' | translate}}</p>
            <div class="lenexa-display">
                <div></div>
            </div>
            <div class="rectangle" id="rectangle">.</div>
            <div class="lenexa-set-description" *ngIf="step5">
                <img class="step5-dashline" src="./assets/images/dashline.svg" alt="dashline img"
                    *ngIf="isHubDisconnected !== true">
                <div class="lenexa-action-content" *ngIf="isHubDisconnected !== true">
                    <p class="set-description-heading">{{'connector.setdescriptionheading' | translate}}</p>
                    <p class="sub-heading">Set Description <span
                            class="heading-light">{{'connector.setdescriptionsubheading' | translate}}</span></p>
                    <div fxLayout="row wrap" class="hub-input-container">
                        <form class="hub-form">
                            <div fxLayout="row" fxLayoutAlign="space-around center">
                                <input class="hub-form-input" type="text" maxlength="50" [(ngModel)]="setDescription"
                                    name="setDescription"
                                    placeholder="{{'connector.setdescriptionplaceholder' | translate}}" />
                            </div>
                        </form>
                    </div>
                    <button mat-button class="lenexa-btn lenexa-btn-secondary pointer" style="min-width: 10vw; height: 38px; border-radius: 20px;" (click)="skipSetDescription()"
                        [ngClass]="{'disabled-class': setDescription}">
                        {{'connector.skip' | translate}}
                    </button>
                    <button mat-button class="lenexa-btn lenexa-btn-primary pointer save-btn" style="min-width: 10vw; height: 38px; border-radius: 20px;" (click)="saveAndNext()">
                        {{'connector.saveandnext' | translate}}
                    </button>
                    <a class="need-help" href="javascript:void(0);">{{'connector.needhelp' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
    
    <div fxLayout="column" *ngIf="isHubDisconnected" class="hub_disconnect_dialog"
        [ngStyle]="{ 'top':  pNumber!= '' ? '-136px' :'-100px' }">
        <div fxLayout="row" class="error_heading_label">
            <p> <img src="./assets/images/error.svg" height="20" style="padding-left: 10px;
        padding-top: 8px;" /><span style="position: relative;
    top: -5px;
    left: 7px;">Disconnection detected</span></p>
        </div>
        <div fxLayout="row">
            <p style="padding-top: 10px;
                padding-left: 80px;"> Lenexa Hub has become disconnected</p>
        </div>
        <div fxLayout="row" style="padding-top:20px;">
            <button class="pair_new_hub_btn pointer" (click)="pairNewHub()">Pair New Hub</button>
            <button class="reconnect_btn pointer" (click)="reconnect()">Reconnect</button>
        </div>
        <p class="hub_disconnect-help-label">{{'connector.needhelp' | translate}}</p>
    </div>
</div>