// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let url = localStorage.getItem('api_url');
export const environment = {
  production: false,
  // SOCKET_ENDPOINT: 'http://ec2-3-25-161-155.ap-southeast-2.compute.amazonaws.com:5000',
  // BASE_URL: 'http://ec2-3-25-161-155.ap-southeast-2.compute.amazonaws.com:5000/v1',
  // BASE_URL:'http://13.236.185.24:5000/v1',
  // SOCKET_ENDPOINT: 'http://13.236.185.24:5000',
  //api_url: 'https://h48bqk5c20.execute-api.ap-southeast-2.amazonaws.com',
  api_url: url,
  BASE_URL: 'http://lenexa-core-web-assets.s3-website-ap-southeast-2.amazonaws.com',
  //BASE_URL: 'http://lenexa-ui-dev.s3-website-ap-southeast-2.amazonaws.com'
  //SOCKET_ENDPOINT: 'http://52.62.189.142',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

