<div fxFlex="100" class="register-wrapper">
  <div fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100">
    <form [formGroup]="orgRegisterForm">
      <div *ngIf="!dataLoader" class="data-spinner">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <div class="Org-data">
        <div fxLayout="column" fxLayoutAlign="center center" class="register-org">
          <label>Organization Details</label>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30">User Name:</label>
            <div class="newOrgUser">
              <input placeholder="Enter userName" type="text" [(ngModel)]="userName" formControlName="userName" name="userName" (change)="handleChange()" required />
              <div class="error-message" *ngIf="orgRegisterForm.get('userName').invalid && (orgRegisterForm.get('userName').dirty || orgRegisterForm.get('userName').touched)">
                <div class="error-message" *ngIf="orgRegisterForm.get('userName').errors?.required" style="color: red;">User Name is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30"> Organization Domain:</label>
            <div class="newOrgUser">
              <input placeholder="Enter Organization Domain" type="text" [(ngModel)]="organizationDomain" formControlName="organizationDomain" name="organizationDomain"
                (change)="handleChange()" required />
              <div class="error-message" *ngIf="orgRegisterForm.get('organizationDomain').invalid && (orgRegisterForm.get('organizationDomain').dirty || orgRegisterForm.get('organizationDomain').touched)">
                <div class="error-message" *ngIf="orgRegisterForm.get('organizationDomain').errors?.required" style="color: red;">Organization Domain is required</div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30">Organization Name:</label>
            <div class="newOrgUser">
              <input placeholder="Enter Organization Name" type="text" [(ngModel)]="organizationName" formControlName="organizationName" name="organizationName"
                (change)="handleChange()" required />
              <div class="error-message" *ngIf="orgRegisterForm.get('organizationName').invalid && (orgRegisterForm.get('organizationName').dirty || orgRegisterForm.get('organizationName').touched)">
                <div class="error-message" *ngIf="orgRegisterForm.get('organizationName').errors?.required">Organization Name is required</div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30">Password:</label>
            <div class="newOrgUser">
              <input placeholder="Enter Password" type="text" [(ngModel)]="Password" formControlName="Password" name="Password" (change)="handleChange()" required />
              <div class="error-message" *ngIf="orgRegisterForm.get('Password').invalid && (orgRegisterForm.get('Password').dirty || orgRegisterForm.get('Password').touched)">
                <div class="error-message" *ngIf="orgRegisterForm.get('Password').errors?.required">Password is required</div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30">Prefered Patient Designation:</label>
            <div class="newOrgUser">
              <input placeholder="Enter Prefered Patient Designation" type="text" [(ngModel)]="preferedPatientDesignation" formControlName="preferedPatientDesignation"
                name="preferedPatientDesignation" (change)="handleChange()" required />
              <div class="error-message" *ngIf="orgRegisterForm.get('preferedPatientDesignation').invalid && (orgRegisterForm.get('preferedPatientDesignation').dirty || orgRegisterForm.get('preferedPatientDesignation').touched)">
                <div class="error-message" *ngIf="orgRegisterForm.get('preferedPatientDesignation').errors?.required">Prefered Patient Designation is required</div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30">Prefered Patient Name:</label>
            <select fxFlex="41" (change)="selectPatientName($event.target.value)">
              <option *ngFor="let pName of patientname" [value]="pName.name">{{pName.name}}</option>
            </select>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="DateSelect">
            <label fxFlex="30">Time_zone:</label>
            <mat-form-field fxFlex="41">
              <mat-label *ngIf="!isValueSelected">Select Time Zone</mat-label>
              <mat-select class="panel-select" (selectionChange)="SelectTimeZone($event)"  (closed)="onSelectMenuClosed()">
                <input type="text" matInput class="input-panel" placeholder="Search for Time Zone" [(ngModel)]="searchKeyword" formControlName="searchKeyword"
                  (input)="filterTimeZones($event.target.value)" (keyup.backspace)="onInputChange()" (closed)="isValueSelected = true">
                <mat-option *ngFor="let tzone of selectedTZone" [value]="tzone">
                  {{ tzone }}
                </mat-option>
                <mat-option *ngIf="selectedTZone.length >= 0" disabled>No time zones
                  found</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30">User Role:</label>
            <select fxFlex="41" (change)="selectUserRole($event.target.value)">
              <option *ngFor="let uRole of UserRole" [value]="uRole.name">{{uRole.name}}</option>
            </select>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="newUser">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="userLabel">
            <label fxFlex="30">No Paired Email List:</label>
            <div class="newOrgUser">
              <input placeholder="Enter Email List" type="email"
                [(ngModel)]="emailList" formControlName="emailList" name="emailList" (change)="handleChange()" />
              <div class="error-message" *ngIf="orgRegisterForm.get('emailList').invalid && (orgRegisterForm.get('emailList').dirty || orgRegisterForm.get('emailList').touched)">
                <div *ngIf="orgRegisterForm.get('emailList').errors?.required">Email is required</div>
                <div *ngIf="orgRegisterForm.get('emailList').errors?.pattern">Invalid email format</div>
              </div>
            </div>
          </div>
        </div>
        <p>(Note*:Multiple emails are accepted with comma)</p>
        <div fxLayout="row" class="newBtn">
          <button name="confirm" class="submit" (click)="organizationDetails()">
            Submit
          </button>
          <button name="confirm" class="clear" (click)="clearForm()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>