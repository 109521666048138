<div class="pdf-graphDirection">
    <div *ngIf="dataToDisplay" class="pdf-graph">
        <p class="idData">{{graphId}}</p>
        <div [class.days-1]="day1 === 1" [class.days-7]="day1 === 2 || day1 === 3 || day1 === 4 || day1 === 5 || day1 === 6 || day1 === 7"
            class="pdf-graphData">
            <div class="foo supine">
                <p>Supine</p>
            </div>
            <div class="foo right">
                <p>Right</p>
            </div>
            <div class="foo prone">
                <p>Prone</p>
            </div>
            <div class="foo left">
                <p>Left</p>
            </div>
            <div class="pdfNoPatient nopatient">
                <p>NPP</p>
            </div>
            <div class="pdfNoData nodata">
                <p>no data</p>
            </div>
        </div>
    </div>
    <div  class="timeline" id="timelineId"></div><!--(resized)="onResized($event)"-->
</div>