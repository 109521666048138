<div class="graph-direction">
    <div *ngIf="dataToDisplay" class="timelineData">
        <p>{{graphId}}</p>
        <div class="timeline-posture">
            <div class="foo supine">
                <p>Supine</p>
            </div>
            <div class="foo right">
                <p>Right</p>
            </div>
            <div class="foo prone">
                <p>Prone</p>
            </div>
            <div class="foo left">
                <p>Left</p>
            </div>
            <div class="colornoPatient nopatient">
                <p>NPP</p>
            </div>
            <div class="colornoData nodata">
                <p>no data</p>
            </div>
        </div>
    </div>
    <div  class="timeline" [id]="timelineId" (resized)="onResized($event)"></div>
</div>