import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private token = JSON.parse(localStorage.getItem('token'));
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient,private baseService: BaseService,) { if (this.token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token.access_token,
      }),
    };
  }}
  

  login(data): Observable<any> {
    return this.http.post('https://yeh7u2wxha.execute-api.ap-southeast-2.amazonaws.com/login', data,this.httpOptions);
  }
  oraganization(data): Observable<any> {
    return this.http.post('https://yeh7u2wxha.execute-api.ap-southeast-2.amazonaws.com/organizations', data, this.httpOptions);
  }
  organizationData(params): Observable<any> {
    return this.baseService.get(`https://yeh7u2wxha.execute-api.ap-southeast-2.amazonaws.com/organizations?${params}`);
  }
}
