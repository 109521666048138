import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService, LoginService, PatientService } from 'src/app/services';

@Component({
  selector: 'app-organization-register',
  templateUrl: './organization-register.component.html',
  styleUrls: ['./organization-register.component.scss']
})
export class OrganizationRegisterComponent implements OnInit {
  organizationDomain: any;
  userName: any;
  Password: any;
  organizationName: any;
  preferedPatientDesignation: any;
  preferedPatientName: any;
  userRole: any;
  emailList: any;
  orgRegisterForm: FormGroup;
  hide: boolean = true;
  errorMessage = false;
  selectTimeZone: any;
  selectedTZone: any;
  timeZone: any = [];
  patientName: any = [];
  dataLoader: boolean = true;
  searchKeyword: any;
  isValueSelected: boolean = false;

  private token = JSON.parse(localStorage.getItem('token'));
  constructor(private formBuilder: FormBuilder, public auth: AuthService, public loginService: LoginService,
    private patientService: PatientService, private http: HttpClient, private router: Router, private toaster: ToastrService,) {

    this.orgRegisterForm = this.formBuilder.group({
      organizationDomain: ['', Validators.required],
      userName: ['', Validators.required],
      Password: ['', Validators.required],
      organizationName: ['', Validators.required],
      preferedPatientDesignation: ['', Validators.required],
      searchKeyword:[''],
      //preferedPatientName: ['', Validators.required],
      //userRole: ['', Validators.required],
      emailList: ['', [Validators.required,Validators.pattern(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in))(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in))*$/)]],
      //selectTimeZone: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.timeZone = moment.tz.names();
    this.timeZoneChanged(this.timeZone);
    this.userRole = this.UserRole[0].name;
    this.preferedPatientName = this.patientname[0].name;
    this.selectTimeZone = this.selectedTZone[0];
  }
  patientname = [
    { name: "Ward {WARD} Bed {BED}" },
    { name: "{BED} in room {WARD}" },
    { name: "Room {WARD} Bed {BED}" }
  ]
  UserRole = [
    { name: "admin" },
    { name: "normal" },
  ]

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }
  timeZoneChanged(timeZone: string): void {
    this.selectedTZone = timeZone;
  }
  filterTimeZones(value: any) {
    this.searchKeyword = value;
    if (!value || value.trim() === '') {
      this.selectedTZone = this.timeZone;
    } else {
      this.selectedTZone = this.selectedTZone.filter(option =>
        option.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
  onInputChange() {
    if (this.searchKeyword || this.selectedTZone.length >= 0) {
      //this.selectedTZone = this.selectedTZone;
      this.filterTimeZonesByName(this.searchKeyword);
    }
  }
  filterTimeZonesByName(value: string) {
    this.searchKeyword = value;
    if (!value || value.trim() === '') {
      this.selectedTZone = this.timeZone;
    } else {
      this.selectedTZone = this.timeZone.filter(option =>
        option.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
  SelectTimeZone(selectedtimeZone: any) {
    this.selectTimeZone = selectedtimeZone.value;
    this.searchKeyword = '';
  }
  onSelectMenuClosed(): void {
    this.isValueSelected = true;
    this.searchKeyword = '';
    this.timeZoneChanged(this.timeZone);
  }
  selectPatientName(selectedPatientName: any) {
    this.preferedPatientName = selectedPatientName;
  }
  selectUserRole(selectedUser: any) {
    this.userRole = selectedUser;
  }
  organizationDetails(): void {
    if (this.auth.isTokenValid()) {
      this.dataLoader = false;
      if (this.orgRegisterForm.valid) {
        const emailListValue = this.orgRegisterForm.get('emailList').value;
        if (emailListValue) {
          var values = emailListValue.split(',');
          var trimmedValues = values.map(value => value.trim());
        }

        let data = {
          "user_name": this.userName,
          "organization_domain_name": this.organizationDomain,
          "organization_name": this.organizationName,
          "password": this.Password,
          "prefered_patient_designation": this.preferedPatientDesignation,
          "prefered_patient_name": this.preferedPatientName,
          "time_zone": this.selectTimeZone,
          "user_role": this.userRole,
          "no_paired_email_list":
            trimmedValues

        }
        this.loginService.oraganization(data).subscribe((res) => {
          if (res.status === "success") {
            this.toaster.success('Organization Details Registered Successfully');
            this.orgRegisterForm.reset();
          }
          this.dataLoader = true;
        }, (error)=>{
          if (error.status === 400) {
            this.dataLoader = false;
            this.toaster.error(error.error.message);
            this.dataLoader = true;
          } else if(error.status === 409) {
            this.dataLoader = false;
            this.toaster.error(error.error.message);
            this.dataLoader = true;
          } else if(error.status === 500) {
            this.dataLoader = false;
            this.toaster.error("Something went wrong. Please contact your system administrator.")
            this.dataLoader = true;
          }
        });
      }
      else{
        this.validateAllFormFields(this.orgRegisterForm);
        this.dataLoader = true;
      }
    } else {
      this.auth.refreshToken();
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }
  handleChange(): void {
    if (
      this.userName !== '' ||
      this.Password != '' ||
      this.organizationName != ''
    ) {
      this.errorMessage = false;
    }
  }
  clearForm(): void {
    this.router.navigate(['organization']);
  }
}
