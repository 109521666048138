import { Component, OnInit } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { Patient } from '../../models/patient';
import { PatientService } from '../../services/patient.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss'],
})
export class AddPatientComponent extends BaseComponent implements OnInit {
  patient: Patient = new Patient();
  patientNumber: string;
  bedNumber: string;
  wardNumber: string;
  bednumber: any;
  isDisabled = true;
  yearData: any[] = [];
  isExisting = 0;
  isEditMode = false;
  ispatientrecordAvailable = false;
  ispatientExists = false;
  severError = false;
  lastCreatedDate: any;
  currentDate: any;
  totalHours: any;
  severErrorMessage = '';
  hubId: any;
  gender: string;
  id: any;
  submitted: boolean;
  wardbednumber: any;
  hasError:boolean;
  hub:any
  description:any;
  mainDescription:any;
  heightoptions: Options = {
    floor: 100,
    ceil: 200,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return (
            '<b> - </b><span class="value-contianer"> <span class="arrow"> </span>' +
            value +
            ' </span> <b>+</b>'
          );
        default:
          return '' + value;
      }
    },
  };
  weightoptions: Options = {
    floor: 30,
    ceil: 150,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return (
            '<b> - </b> <span class="value-contianer"> <span class="arrow"> </span>' +
            value +
            ' </span> <b>+</b>'
          );
        default:
          return '' + value;
      }
    },
  };
  addPatientForm: FormGroup;
  subdomain:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    public auth: AuthService,
  ) {
    super();
    this.addPatientForm = this.formBuilder.group({
      wardNumber: [null, Validators.required],
      bednumber: [null, Validators.required],
    });
    this.submitted = false;
  }

  ngOnInit(): void {
    for (let i = 1940; i < 2020; i++) {
      this.yearData.push(i);
    }
    this.subdomain = localStorage.getItem('base_org');
    this.patientNumber = this.activeRoute.snapshot.queryParams.pnumber;
    this.bedNumber = this.patientService.getBedNumber();
    this.hubId = JSON.parse(localStorage.getItem('hub_id'));
    this.hub = this.hubId[0].hubId;
    this.description = JSON.parse(localStorage.getItem('hub_description'));
    this.mainDescription = this.description.hub_description
  }

  onChangeBed(): void {
    this.ispatientExists = false;
  }

  savePatient(): void {
    this.patient.created_at = new Date();
    this.patient.updated_at = new Date();
    this.patient.active = '1';
    if (this.patient.id) {
      this.updatePatient(this.patient.patient_number);
    } else {
      this.patient.hub_id = this.hubId;
      const addPatientSubscriber = this.patientService
        .addPatient(this.patient)
        .subscribe(
          (data: any) => {
            if (data.status === 'Success') {
              if (this.patient.sex == 'M') {
                this.patientService.setGender('male');
              }
              else if (this.patient.sex == 'F') {
                this.patientService.setGender('female');
              }
              else {
                this.patientService.setGender('other');
              }
              this.patientService.setPatientNumber(data.patient_number);
              this.patientService.setRiskAssessmentCount(0);
              this.router.navigate(['/dashboard']);
              this.toaster.success('Patient added successfully', '');
            } else {
              this.toaster.error(data.result, '');
              this.severError = true;
              this.severErrorMessage = data.result;
            }
          },
          (error) => console.log(error)
        );
      this.subscribers.push(addPatientSubscriber);
    }
  }
  handleChange(): void {
    if (
      this.patient.patient_number &&
      this.patient.sex !== undefined &&
      this.patient.height > 100 &&
      this.patient.weight > 30 &&
      this.patient.yob !== 0 &&
      this.patient.yob !== null &&
      this.patient.yob !== ''
    ) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  getPatientById(id): void {
    const getPatientSubscriber = this.patientService
      .getPatientById(id)
      .subscribe((data) => {
        let wardbednumber = data[0]?.patient_number;
        this.wardNumber = wardbednumber?.split('-')[0].split('WARD').pop();
        this.bednumber = wardbednumber?.split('-')[1].split('BED').pop();
        if (data.status !== 'Error') {
          this.patient = data[0];
          this.id = data[0].id;
          this.ispatientrecordAvailable = true;
          this.ispatientExists = false;
          if (!this.isEditMode) {
            this.patient.weight = 30;
            // this.toaster.success('Patient Successfully Found!', '');
          }
          this.handleChange();
        } else {
          // this.ispatientExists = true;
        }
      });
    this.subscribers.push(getPatientSubscriber);
  }

  updatePatient(id): void {
    this.patient.hub_id = this.hubId;
    this.patient.patient_number = 'WARD' + this.wardNumber + '-' + 'BED' + this.bednumber;
    const updatePatientSubacriber = this.patientService
      .updatePatient(id, this.patient)
      .subscribe(
        (response) => {
          if (response.status === 'Success') {
            this.patientService.setPatientNumber(this.patient.patient_number);
            if (this.patient.sex == 'M') {
              this.patientService.setGender('male');
            }
            else if (this.patient.sex == 'F') {
              this.patientService.setGender('female');
            }
            else {
              this.patientService.setGender('other');
            }
            this.patientService.setPatientId(this.patient.id);
            // if (this.patientNumber) {
            //   this.router.navigate(['/dashboard']);
            // } else {
            //   this.decideNavigation();
            // }
            // this.toaster.success('Patient updated successfully', '');
          } else {
            this.toaster.error(response.result, '');
          }
        },
        (error) => {
          console.log(error);
        }
      );
    this.subscribers.push(updatePatientSubacriber);
  }

  decideNavigation(): void {
  }

  retrievePatient(): void {
    this.getPatientById(this.patient.patient_number);
  }

  handlePatientChange(): void {
    this.severError = false;
    this.ispatientExists = false;
    if (!this.isExisting) {
      this.patient = new Patient();
      this.ispatientrecordAvailable = false;
    }
  }

  handlePatientNumberChange(): void {
    this.severError = false;
    this.ispatientExists = false;
    this.ispatientrecordAvailable = false;
    this.patient.id = null;
  }
  saveBed(): void {
    this.submitted = true;
    this.hasError = this.wardNumber?.includes(' ') || this.bednumber?.includes(' ');
    if(this.hasError){
      this.toaster.error("Spaces are not allowed");
      this.submitted = false;
    }
    else if (this.addPatientForm.invalid) {
      this.toaster.error("Please Enter Ward and Bed Numbers");
      this.submitted = false;
    }
    else {
      let data = this.addPatientForm.value;
      if (data.wardNumber && data.bednumber) {
        this.wardbednumber = 'Ward' + data.wardNumber + '-' + 'Bed' + data.bednumber;
        //this.patientService.setBedNumber(this.wardbednumber);
        let patient = {
          created_at: new Date(),
          updated_at: new Date(),
          is_active: '1',
          patient_number: this.wardbednumber,
          hub_id: this.hub,
          ward:data.wardNumber,
          bed:data.bednumber,
          description:this.mainDescription
        }
        this.patientService.addPatient(patient).subscribe(onSuccess.bind(this), onError.bind(this));

        function onSuccess(this: AddPatientComponent, result): void {
          this.submitted = false;
          if (result.status =='error') {
            this.toaster.error(result.message);
          } 
          if (result.status == 'success') {
            this.router.navigate([ `/${this.subdomain}/dashboard`]);
          }
          else this.toaster.error(result);
        }

        function onError(this: AddPatientComponent, error): void {
          this.submitted = false;
          if (error.status =='error') {
            this.toaster.error(error.message);
          } 
          this.toaster.error("An error occurred while processing the request.");
        }
      }
    }
  }
}
