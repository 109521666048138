import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import { PatientService } from '../../services';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() width: number;
  @Input() height: number;


  // margin to graph
  margin: any = {
    left: 30,
    right: 30,
    top: 10,
    bottom: 25,
  };

  // padding text inside bar
  padding: any = {
    left: 20,
    top: 15,
  };

  // curved bars on top
  rx = 10;
  ry = 10;

  // space between space
  rectPadding = 0.5;

  chart: any;
  xScale: any;
  yScale: any;
  colors: any;
  xAxis: any;
  yAxis: any;
  containerSize: any;
  svg: any = null;

  constructor(public patientService: PatientService) { }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.data.length > 0) {
      this.createChart();
      this.updateChart();
    }
  }



  createChart(): void {
    this.svg = d3
      .select('#barChart')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')

    this.containerSize = (this.svg.node() as any).getBoundingClientRect();
    this.width = 600;
    // this.containerSize.width - this.margin.left - this.margin.right;
    this.height =
      this.containerSize.height - this.margin.top - this.margin.bottom;

    // chart plot area
    this.chart = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    // define X & Y domains
    const xDomain = this.data.map((d) => d.risk_area);
    const yDomain = [0, d3.max(this.data, (d) => d.percentage)];

    // create scales
    this.xScale = d3
      .scaleBand()
      .padding(this.rectPadding)
      .paddingInner(0.7)
      .domain(xDomain)
      .rangeRound([0, this.width]);

    this.yScale = d3
      .scaleLinear()
      // .domain(yDomain)
      .range([this.height, 30]);

    // bar colors
    this.colors = d3
      .scaleLinear()
      .domain(this.data.map((d) => d.risk_area))
      .range(this.data.map((d) => d.color));

    // x & y axis
    this.xAxis = this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${this.margin.left}, ${this.margin.top + this.height})`
      )

      .call(d3.axisBottom(this.xScale));

    this.yAxis = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
      .call(d3.axisLeft(this.yScale));
  }

  updateChart(): void {
    // update scales & axis
    this.xScale.domain(this.data.map((d) => d.risk_area));
    this.yScale.domain([0, d3.max(this.data, (d) => d.percentage)]);
    this.colors.domain([0, this.data.length]);
    this.xAxis.transition().call(d3.axisBottom(this.xScale));
    this.yAxis
      .transition()
      .call(d3.axisLeft(this.yScale))
      .call((g) => g.remove());

    const update = this.chart.selectAll('.bar').data(this.data);

    // remove exiting bars
    update.exit().remove();

    // update existing bars
    this.chart
      .select('.bar')
      .transition()
      .attr('x', (d) => this.xScale(d.risk_area))
      .attr('y', (d) => this.yScale(d.percentage))
      .attr('width', (d) => this.xScale.bandwidth())
      .attr('height', (d) => this.height - this.yScale(d.percentage))
      .style('fill', (d, i) => this.colors(i));

    // add new bars
    update
      .enter()
      .append('path')
      .attr('x', (d) => this.xScale(d.risk_area))
      .attr('width', this.xScale.bandwidth())
      .style('fill', (d, i) => this.colors(i))
      .attr('y', (d) => this.yScale(d.percentage))
      .attr('height', (d) => this.height - this.yScale(d.percentage))
      .attr(
        'd',
        (item) => ` 
      M${this.xScale(item.risk_area)},${this.yScale(item.percentage) + this.ry}
      a${this.rx},${this.ry} 0 0 1 ${this.rx},${-this.ry}
      h${this.xScale.bandwidth() - 2 * this.rx}
      a${this.rx},${this.ry} 0 0 1 ${this.rx},${this.ry}
      v${this.height - this.yScale(item.percentage) - this.ry}
      h${-this.xScale.bandwidth()}Z
    `
      );
    this.drawText();
  }

  drawText(): void {
    this.svg
      .selectAll('.text')
      .data(this.data)
      .enter()
      .append('text')
      .attr('fill', 'white')
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      )
      .attr('x', (d) => this.xScale(d.risk_area))
      .attr('y', (d) => this.yScale(d.percentage))
      .attr('dx', () => {
        return (this.xScale.bandwidth() - this.padding.left) / 2;
      })
      .attr('dy', (d) => this.padding.top)
      .text((d) => d.percentage);
  }
}
