import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Patient, Notes } from '../models/patient';
import { urlList } from '../urlConstants';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService {

  isPause: any;
  patientType: any;
  notesData: any = [];
  hubidChecked: any[];
  public pauseSubject = new BehaviorSubject<any>({});
  public sheetId: BehaviorSubject<any>;
  public sheetIdObservable: Observable<any>;

  private loginResponseSubject = new BehaviorSubject<any>([]);
  logicData = this.loginResponseSubject.asObservable();


  setLoginResponse(logicData: any): void {
    this.loginResponseSubject.next(logicData);
  }


  constructor(private baseService: BaseService, private http: HttpClient) {
    this.sheetId = new BehaviorSubject<any>('');
    this.sheetIdObservable = this.sheetId.asObservable();
  }

  private logoutSubject = new Subject<void>();

  logoutEvent = this.logoutSubject.asObservable();

  emitLogoutEvent(): void {
    this.logoutSubject.next();
  }

  private selectedHubIdsSubject = new BehaviorSubject<any>([]);
  selectedHubIds = this.selectedHubIdsSubject.asObservable();

  setSelectedHubIds(selectedHubIds: string[]) {
    this.selectedHubIdsSubject.next(selectedHubIds);
  }  
  addPatient(patient): Observable<any> {
    return this.baseService.post(`/patient`, patient);
  }

  setPatientNumber(num): void {
    localStorage.setItem('pNumber', num);
  }

  setBedNumber(bednum): void {
    localStorage.setItem('Ward_Bed Number', bednum);
  }

  setHubId(hubId): void {
    localStorage.setItem('hub_id', hubId);
  }

  setGender(gender): void {
    localStorage.setItem('gender', gender);
  }

  getPatientNumber(): string {
    return localStorage.getItem('pNumber');
  }

  getBedNumber(): string {
    return localStorage.getItem('Ward_Bed Number');
  }

  getHubId(): string {
    return localStorage.getItem('hub_id');
  }

  getPatientId(): number {
    return Number(localStorage.getItem('pId'));
  }

  getGender(): string {
    return localStorage.getItem('gender');
  }

  setPatientId(id: number): void {
    localStorage.setItem('pId', id.toString());
  }

  setRiskAssessmentCount(count: number): void {
    localStorage.setItem('risk_assessment_count', count.toString());
  }

  getRiskAssessmentCount(): number {
    return Number(localStorage.getItem('risk_assessment_count'));
  }

  getPatientById(patientNumber): Observable<any> {
    return this.baseService.getWithToken(`/patient/${patientNumber}`);
  }

  updatePatient(patientNumber, data): Observable<any> {
    return this.baseService.put(`/patient/${patientNumber}`, data);
  }

  increaseRiskAssessmentCount(): void {
    const riskCount = this.getRiskAssessmentCount() + 1;
    this.setRiskAssessmentCount(riskCount);
  }

  getFaultsAlert(id, data): Observable<any> {
    return this.baseService.post(`/patient/${id}/fault_alert`, data);
  }

  listofhubids(): Observable<any> {
    return this.baseService.getWithToken('/get_hub_id');
  }
  unpairPatients(data): Observable<any> {
    return this.baseService.post('/unpair', data);
  }
  listOfPatients(params): Observable<any> {
    return this.baseService.getWithToken(`/getdashboarddata?${params}`)
  }
  graphData(params): Observable<any> {
    return this.baseService.getWithToken(`/graphs?${params}`);
  }
  patientConfig(patient): Observable<any> {
    return this.baseService.getWithToken(`/patient/config/${patient}`)
  }
  updatePatientConfig(patient,data): Observable<any> {
    return this.baseService.patch(`/patient/config/${patient}`,data);
  }
  dataBoxRegister(data): Observable<any> {
    return this.baseService.post('/databox_register',data);
  }
  validateHubid(data): Observable<any> {
    return this.baseService.post('/validate_databox',data);
  }
  hubidVerification(data): Observable<any> {
    return this.baseService.post('/check_mapping_status',data);
  }
  patientReport(start,end,patient): Observable<any> {
    return this.baseService.getWithToken(`/reports/${start}/${end}/${patient}`)
  }
  genPatientReport(start,end,patient): Observable<any> {
    return this.baseService.getWithToken(`/generate-report/${start}/${end}/${patient}`)
  }
}
