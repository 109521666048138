import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionServiceModule } from 'ng-connection-service';
/*ng5 Slider Imports*/
import { Ng5SliderModule } from 'ng5-slider';
import 'd3';
/*MaterialUI Imports*/
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularResizedEventModule } from 'angular-resize-event';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { NgSelectModule } from '@ng-select/ng-select';
/*Component Imports */
import { AppComponent } from './app.component';
import {
  BaseComponent,
  LoaderComponent,
  HeaderComponent,
  LoginComponent,
  DialogComponent,
  LenexaSheetConnectorComponent,
  AddPatientComponent,
  DashboardComponent,
  LenexaStepperComponent,
  TimelineComponent,
  BarChartComponent,
  PdfComponent,
} from './components';
import { PatientDataboxListComponent } from './components/patient-databox-list/patient-databox-list.component';
import { DataBoxKinesisConfigurationComponent } from './components/data-box-kinesis-configuration/data-box-kinesis-configuration.component';
import { OrganizationRegisterComponent } from './components/organization-register/organization-register.component';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { PairingDailogComponent } from './components/pairing-dailog/pairing-dailog.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { TimelineDataComponent } from './components/timeline-data/timeline-data.component';


declare var $: any;
@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    LoaderComponent,
    HeaderComponent,
    LoginComponent,
    DialogComponent,
    BaseComponent,
    LenexaSheetConnectorComponent,
    AddPatientComponent,
    DashboardComponent,
    LenexaStepperComponent,
    TimelineComponent,
    BarChartComponent,
    PdfComponent,
    PatientDataboxListComponent,
    DataBoxKinesisConfigurationComponent,
    OrganizationRegisterComponent,
    OrganizationListComponent,
    PairingDailogComponent,
    DonutChartComponent,
    TimelineDataComponent,
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    ConnectionServiceModule,
    Ng5SliderModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatCardModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTableModule,
    FormsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    ClickOutsideModule,
    AngularResizedEventModule,
    MatPaginatorModule,
    OwlDateTimeModule,
     OwlNativeDateTimeModule,
     NgSelectModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [DatePipe, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} } ],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
