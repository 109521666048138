import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RiskStep } from '../../models/riskAssessmentModels';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { Patient } from '../../models/patient';
import { PatientService } from 'src/app/services';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PairingDailogComponent } from '../pairing-dailog/pairing-dailog.component';

@Component({
  selector: 'app-lenexa-sheet-connector',
  templateUrl: './lenexa-sheet-connector.component.html',
  styleUrls: ['./lenexa-sheet-connector.component.scss'],
})
export class LenexaSheetConnectorComponent extends BaseComponent implements OnInit {
  patient: Patient = new Patient();
  connectionSteps = true;
  step1 = true;
  step2 = false;
  subStep2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  hubId: any;
  hubIdInput1: string;
  hubIdInput2: string;
  hubIdInput3: string;
  hubIdInput4: string;
  errorMessage = false;
  pairedSuccessfully = false;
  setDescription: string;
  hub_id: string;
  hub_description: string;
  hideriskSteps = false;
  pNumber: any;
  bedNumber: any;
  routerStateSnapshot: any;
  isHubDisconnected: any;
  wardBednumber: any;
  riskSteps: RiskStep[] = [
    {
      name: 'Connect Cables',
      active: true,
      done: false,
    },
    {
      name: 'Check Lights',
      active: false,
      done: false,
    },
    {
      name: 'Connect Hub',
      active: false,
      done: false,
    },
    {
      name: 'Enter Hub ID',
      active: false,
      done: false,
    },
    {
      name: 'Pair Display',
      active: false,
      done: false,
    },
    {
      name: 'Finish & Add Patient',
      active: false,
      done: false,
    },
  ];
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public patientService: PatientService,
    public auth: AuthService,
    public activeRoute: ActivatedRoute,
    public toaster: ToastrService,
  ) {
    super();
    this.routerStateSnapshot = this.router.routerState.snapshot;
  }
  ngOnInit(): void {
    this.init();
    this.isHubDisconnected = this.activeRoute.snapshot.queryParams.isHubDisconnected;
    this.pNumber = localStorage.getItem('pNumber') || '';
    let wardBedNumber = this.patientService.getBedNumber();
    let wardNumber = wardBedNumber?.split('-BED')[0].split('WARD').pop();
    let bedNumber = wardBedNumber?.split('-BED')[1].split('BED').pop();
    this.wardBednumber = 'Ward:' + " " + wardNumber + " " + 'Bed:' + " " + bedNumber;
    this.bedNumber = localStorage.getItem('Ward_Bed Number') || ''
  }

  init() {
    this.hub_id = localStorage.getItem('hub_id') || '';
    let storeHub = JSON.parse(localStorage.getItem('hub_id') || '[]');
    if (storeHub && storeHub.length > 0) {
      this.hub_id = storeHub[0].hubId;
    }
    let storeDescrption = JSON.parse(localStorage.getItem('hub_description') || '{}');
    if (storeDescrption && Object.keys(storeDescrption).length > 0) {
      this.hub_description = storeDescrption.hub_description;
    }
    this.pNumber = localStorage.getItem('pNumber') || '';
    this.bedNumber = localStorage.getItem('Ward_Bed Number') || '';
    if (this.hub_id !== '') {
      this.connectionSteps = false;
      for (let i = 0, len = this.riskSteps.length; i < len; i++) {
        if (i !== 5) {
          this.riskSteps[i].done = true;
          this.riskSteps[i].active = false;
          this.hideriskSteps = true;
        } else {
          this.riskSteps[i].done = false;
          this.riskSteps[i].active = true;
        }
      }
    }
  }

  onAddPatientClick(): void {
    this.router.navigate(['./addpatient']);
  }

  navigateToDashboard(): void {
    this.router.navigate(['./dashboard']);
  }

  cableConnected(): void {
    this.step1 = false;
    this.step2 = true;
    this.riskSteps[0].done = true;
    this.riskSteps[0].active = false;
    this.riskSteps[1].active = true;
  }
  step2Back(): void {
    this.step1 = true;
    this.step2 = false;
    this.riskSteps[0].done = false;
    this.riskSteps[0].active = true;
    this.riskSteps[1].active = false;
  }
  lightsMatch(): void {
    this.step2 = false;
    this.subStep2 = true;
    this.riskSteps[1].done = true;
    this.riskSteps[1].active = false;
    this.riskSteps[2].active = true;
  }
  subStep2Back(): void {
    this.step2 = true;
    this.subStep2 = false;
    this.riskSteps[1].done = false;
    this.riskSteps[1].active = true;
    this.riskSteps[2].active = false;
  }
  subLightsMatch(): void {
    this.subStep2 = false;
    this.step3 = true;
  }
  step3Back(): void {
    this.subStep2 = true;
    this.step3 = false;
  }
  flashing(): void {
    this.step3 = false;
    this.step4 = true;
    this.riskSteps[2].done = true;
    this.riskSteps[2].active = false;
    this.riskSteps[3].active = true;
  }
  step4Back(): void {
    this.step3 = true;
    this.step4 = false;
    this.riskSteps[2].done = false;
    this.riskSteps[2].active = true;
    this.riskSteps[3].active = false;
  }

  onDigitInput(event): void {
    let element;
    if (event.key !== 'Shift' && event.key !== 'CapsLock') {
      element = event.key !== 'Backspace'
        ? event.srcElement.nextElementSibling
        : event.srcElement.previousElementSibling;
    }
    if (element) {
      element.focus();
    }
  }

  connectorDone(): void {
    const hubId = this.hubIdInput1 + this.hubIdInput2 + this.hubIdInput3 + this.hubIdInput4;
    let data = {
      "hub_id": hubId
    };
    this.patientService.validateHubid(data).subscribe((res) => {
      if (res.status === 'Success') {
        if (res.Result === 'hub_id exists') {
          this.patientService.hubidVerification(data).subscribe((resp) => {
            if (resp.status === 'success') {
              if (resp.paired == 'yes') {
                let dialogRef: any;
                dialogRef = this.dialog.open(PairingDailogComponent, {
                  width: '500px',
                  
                  data: {
                    type: 'alert',
                    header: `Warning:<br /><p>This Hub is currently paired with another bed.</p>`,
                    headerImg: 'assets/images/closeIcon.png',
                    buttonsAlign: 'horizantal',
                    label: resp.data,
                    yesBtn: 'Confirm',
                    cancelBtn: 'Cancel',
                  },
                });
                const dialogSubscriber = dialogRef?.afterClosed().subscribe((result) => {
                  if (result === 'yes') {
                    let hubid = [];
                    hubid.push({ hubId });
                    localStorage.setItem('hub_id', JSON.stringify(hubid));
                    this.init();
                    this.setDescription = ""
                    this.step4 = false;
                    this.connectionSteps = false;
                    this.pairedSuccessfully = true;
                    this.riskSteps[3].done = true;
                    this.riskSteps[3].active = false;
                    this.riskSteps[4].active = true;
                    this.step5 = true;
                    setTimeout(() => {
                      this.pairedSuccessfully = false;
                      this.riskSteps[3].done = true;
                      this.riskSteps[3].active = false;
                    }, 3000);
                    this.errorMessage = false;
                  }
                });
              }
            }
            if (resp.paired == 'no') {
              let hubid = [];
              hubid.push({ hubId });
              localStorage.setItem('hub_id', JSON.stringify(hubid));
              this.init();
              this.setDescription = ""
              this.step4 = false;
              this.connectionSteps = false;
              this.pairedSuccessfully = true;
              this.riskSteps[3].done = true;
              this.riskSteps[3].active = false;
              this.riskSteps[4].active = true;
              this.step5 = true;
              setTimeout(() => {
                this.pairedSuccessfully = false;
                this.riskSteps[3].done = true;
                this.riskSteps[3].active = false;
              }, 3000);
              this.errorMessage = false;
            }
          })
        }
        else {

        }
      }
      else {
        this.errorDialog();
      }
    });
  }

  errorDialog() {
    let dialogRef: any;
    dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        type: 'alert',
        headerImg: 'assets/images/closeIcon.png',
        buttonsAlign: 'horizantal',
        label: `<b>HUB ID not found</b><br><br>Please check and try again`,
        yesBtn: 'Ok',
        cancelBtn: 'Cancel',
      },
    });
  }

  saveAndNext(): void {
    if (this.setDescription || !this.setDescription) {
      const hubId = localStorage.getItem('hub_id');
      const data = {
        hub_description: this.setDescription,
      };
      this.step5 = false;
      this.riskSteps[5].active = true;
      if (this.setDescription == undefined) {
        localStorage.setItem('hub_description', '');
        this.setDescription = "";
      }
      else {
        localStorage.setItem('hub_description', JSON.stringify(data));
      }
      this.init();
    }
  }

  skipSetDescription(): void {
    this.step5 = false;
  }

  closeSheetDialog(): void {
  }

  closeHubDialog(): void {
    if (this.auth.isTokenValid()) {
      localStorage.removeItem('hub_id');
      localStorage.removeItem('hub_description')
      this.hub_description = "";
      this.connectionSteps = true;
      this.hideriskSteps = false;
      for (let i = 0, len = this.riskSteps.length; i < len; i++) {
        if (i === 0) {
          this.riskSteps[i].done = false;
          this.riskSteps[i].active = true;
        } else {
          this.riskSteps[i].done = false;
          this.riskSteps[i].active = false;
        }
      }
      this.step4 = false;
      this.step3 = false;
      this.step2 = false;
      this.step1 = true;
      this.step5 = false;
      this.hubIdInput1 = '';
      this.hubIdInput2 = '';
      this.hubIdInput3 = '';
      this.hubIdInput4 = '';
    }
    else {
      this.auth.refreshToken();
    }

  }

  alertDialog(): void {
    let dialogRef: any;
    dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: {
        type: 'alert',
        icon: 'fa fa-exclamation-circle',
        header: 'Network Issue',
        buttonsAlign: 'horizantal',
        label: `Check your network connection`,
        yesBtn: 'reviewassessment.dialog.ok',
        cancelBtn: 'reviewassessment.dialog.cancel',
      },
    });
  }

  faultDialog(): void {
    let dialogRef: any;
    dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: {
        type: 'fault',
        icon: 'fa fa-exclamation-circle',
        header: 'Network Issue',
        buttonsAlign: 'horizantal',
        label: `Check your network connection`,
        yesBtn: 'reviewassessment.dialog.viewproblem',
      },
    });
  }

  editHubDescription(): void {
    this.step5 = true;
    if (this.hub_description != '') { this.setDescription = this.hub_description; }
  }

  keyPress(data): void {
    if (data === this.hubIdInput1) {
      this.hubIdInput1 = '';
    }
    if (data === this.hubIdInput2) {
      this.hubIdInput2 = '';
    }
    if (data === this.hubIdInput3) {
      this.hubIdInput3 = '';
    }
    if (data === this.hubIdInput4) {
      this.hubIdInput4 = '';
    }
  }

  pairNewHub(): void {
    this.isHubDisconnected = false;
    this.closeHubDialog();
  }

  reconnect(): void {
    this.isHubDisconnected = false;
    this.step4 = true;
    this.step3 = false;
    this.step2 = false;
    this.step1 = false;
    this.step5 = false;
    this.connectionSteps = true;
    this.hideriskSteps = false;
    for (let i = 0, len = this.riskSteps.length; i < len; i++) {
      if (i === 3) {
        this.riskSteps[i].done = false;
        this.riskSteps[i].active = true;
      } else if (i < 3) {
        this.riskSteps[i].done = true;
        this.riskSteps[i].active = false;
      }
      else {
        this.riskSteps[i].done = false;
        this.riskSteps[i].active = false;
      }
    }
    this.hubIdInput1 = this.hub_id[0];
    this.hubIdInput2 = this.hub_id[1];
    this.hubIdInput3 = this.hub_id[2];
    this.hubIdInput4 = this.hub_id[3];

  }
}

