<div fxLayout="column" fxLayoutAlign="start end">
    <div fxLayout="row" class="w-100">
        <div class="unpairDialog-content">
            <p>Please select the databox(es) to be unpaired</p>
            <hr />
            <div class="dialog-selection" *ngFor="let hubId of data.hubIds">
                <div *ngIf="hubId; else emptyHubId">
                    <input type="checkbox" class="checkbox_type" (change)="toggleCheckbox($event,hubId)"
                        [checked]="selectedCheckboxes.includes(hubId)" />
                    <span [class.checkbox-highlighted]="selectedCheckboxes.includes(hubId)">{{ hubId }}</span>
                </div>
            </div>
        </div>
        <img class="image-icon" (click)="onSubmit('no')" src="assets\images\closeIcon.png">
    </div>
    <mat-dialog-actions fxLayout="row" class="dialog-buttons-horizantal" *ngIf="data.buttonsAlign !== 'horizantal'">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="unpair-btn"
            [class.unpair-btn]="!isUnpairButtonDisabled()" [class.unpair-btn-selected]="isUnpairButtonDisabled()"
            [disabled]="isUnpairButtonDisabled()" (click)="onSubmit('yes')">
            {{data.yesBtn | translate}}</button>
        <button mat-button mat-dialog-close class="cancel-btn" *ngIf="data.cancelBtn"
            (click)="onSubmit('no')">{{data.cancelBtn |translate}}</button>
    </mat-dialog-actions>
</div>
<ng-template #emptyHubId>
    <div class="empty-hub-id-message">
        No hubIDs are available.
    </div>
</ng-template>