export class Patient {
  patient_number: string;
  id: number;
  sex: string;
  created_at?: Date;
  updated_at?: Date;
  active?: string;
  height = 100;
  weight = 30;
  yob: number | string = '';
  hub_id = '';
}

export class Notes {
  id: number;
  pressure_time: string;
}
