<div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div class="main-container" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayoutAlign="center center">
        <form id="contentToConvert">
            <mat-card class="form-group custom pdf-card">
                <div fxLayout="row wrap" class="pad-20">
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%">
                        <p class="lenexa-heading">MEDICAL REPORT </p>
                        <p class="lenexa-heading pad-top-20">{{patient_number}}</p>
                        <p class="record-number-label">{{ "addpatient.patientcardnumber" |translate
                            }}</p>
                        <hr>
                    </div>
                </div>
                <mat-card-content class="pad-left-20 pad-right-20" *ngFor='let data of pdfData; let indexOfEle = index'>
                    
                    <div *ngFor="let risk of data.riskData;">
                        <p class="date-label"> {{ currentDate | date:"dd MMM y"}}</p>
                        <p class="position-label">{{risk.position}} <span class="pad-left-30">{{risk.starting_date |
                                date:"HH:mm"}} - {{risk.ending_date | date:"HH:mm"}}</span></p>
                                <table>
                                    <tr class="risk-header">
                                        <th>Body part</th>
                                        <th>Pressure injury risk</th>
                                        <th>Time</th>
                                        <th>Notes</th>
                                        <th>Comments</th>
                                    </tr>
                                    <tr *ngFor="let item of risk.risks">
                                        <td>{{item.body_part}}</td>
                                        <td
                                            [ngClass]="{ 'high_pressureinjury': item.risk_level == 'High Risk', 'low_pressureinjury': item.risk_level =='Medium Risk'}">
                                            {{item.risk_level}}</td>
                                        <td>{{item.starting_date | date:"HH:mm"}} - {{item.ending_date | date:"HH:mm"}}</td>
                                        <td>{{item.notes}}</td>
                                        <td>{{item.comments}}
                                        </td>
                                    </tr>
                                </table>
                    </div>
                    <p class="pad-top-10">Statics</p>
                    <div fxLayout="row" class="pad-top-10" style="border-bottom:1px solid #00000029;" >
                        <div fxLayout="column" fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" class=" margin-right-20"
                            style="height:380px;">
                            <app-donut-chart [data]="data.donut_data" [isLegend]="true" [legendcurrentId]=" 'legend' + indexOfEle " 
                            [donutcurrentId]=" 'donut' + indexOfEle " 
                            style="margin-top:-30px;">
                            </app-donut-chart>
                        </div>
                        <div fxLayout="column" fxFlex="35" fxFlex.sm="100" fxFlex.xs="100"
                            style="margin-left:30px;margin-top: 30px;border-left: 1px solid #00000029;">
                            <div class="pad-30">
                                <div class="body">{{"history.static.position" | translate}}</div>
                                <hr style="margin-left:-10px;" />
                                <div class="title">{{staticPosition[indexOfEle]}}</div>
                                <div class="sub-title" *ngIf="data.donut_data.care_averages.position == 'left'">Left side{{"history.position" |
                                    translate}}</div>
                                <div class="sub-title" *ngIf="data.donut_data.care_averages.position == 'right'">Right side{{"history.position" |
                                    translate}}</div>
                                <div class="sub-title" *ngIf="data.donut_data.care_averages.position !='left' && data.donut_data.care_averages.position!= 'right'">
                                    {{data.donut_data.care_averages.position}}{{"history.position" | translate}}</div>
                                <div class="body pad-top-30">{{"history.average.turn.frequency" | translate}}</div>
                                <hr style="margin-left:-10px;" />
                                <div class=" title" *ngIf="averageTurnFrequency">Every {{averageTurnFrequency[indexOfEle]}}h
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </form>
        <input type="button" value="Convert" (click)="convertToPDF()" />
    </div>
</div>
