import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../components';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  tokenExpairyTime: number = 30 * 60 * 1000;
  isRefreshingToken: boolean = false;
  isFirstNavigation = true;

  constructor(private dialog: MatDialog, private http: HttpClient,private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!this.isFirstNavigation) {
        this.refreshTokenIfNeeded();
      }
      this.isFirstNavigation = false;
    });
   }

  setToken(authToken: string): void {
    const token = {
      access_token: authToken,
      expires: new Date().getTime() + this.tokenExpairyTime,
    };
    localStorage.setItem('token', JSON.stringify(token));
  }

  isTokenValid(): boolean {
    const now = new Date().getTime();
    const token = JSON.parse(localStorage.getItem('token'));

    if (token && token.access_token && token.expires > now) {
      return true;
    } 
    return false;
  }

  refreshTokenIfNeeded(): void {
    if (!this.isRefreshingToken && !this.isTokenValid()) {
      this.refreshToken();
    }
  }

  refreshToken(url: string = null) {
    if (this.isRefreshingToken) {
      return;
    }
    this.isRefreshingToken = true;
    const refreshToken = localStorage.getItem("refreshtoken");
    this.http.post<any>('https://yeh7u2wxha.execute-api.ap-southeast-2.amazonaws.com/refresh', { refresh_token: refreshToken }).subscribe(
      (response) => {
        const newAccessToken = response.access_token;
        if (url) {
          this.router.navigateByUrl(url);
        }
        this.setToken(newAccessToken);
        this.isRefreshingToken = false;
      },
      (error) => {
        console.error('Error refreshing token:', error);
        this.router.navigate(['/login']);
      },
      () => {
        this.isRefreshingToken = false;
      }
    );
  }
  // openPasscodeDialog(url: string = null): any {
  //   return this.dialog.open(LoginComponent, {
  //     disableClose: true,
  //     data: {
  //       returnUrl: url,
  //       isDialog: true,
  //     },
  //   });
  // }
 }
