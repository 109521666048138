import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService, BackdropService } from './services';
import { BaseComponent } from './components';
import { ConnectionService } from 'ng-connection-service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './../app/components/dialog/dialog.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  isloading = true;
  isHome = false;
  isBackDropRequied = false;
  dialogRef:any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private backDrop: BackdropService,
    private connectionService: ConnectionService,
    public dialog: MatDialog,
    public auth: AuthService,
  ) {
    super();
    translate.setDefaultLang('en');
    this.connectionService.monitor().subscribe((isConnected) => {
      if (!isConnected) {
        this.openConnectionDialog();
      }
      else {
        this.closeConnectionDialog();
      }
    });
  }

  ngOnInit(): void {
    console.log("perfect")
    this.router.events.subscribe((route: { [key: string]: any }) => {
      if (
        route['routerEvent'] &&
        route['routerEvent'].urlAfterRedirects === '/'
      ) {
        this.isHome = true;
      }
    });
    setTimeout(() => {
      this.isloading = false;
    }, 3500);

    const backDropSubScriber = this.backDrop
      .getBackDropState()
      .subscribe((value) => {
        console.log('value', value);
        this.isBackDropRequied = value;
      });

    this.subscribers.push(backDropSubScriber);
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }

  openConnectionDialog(): void {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: {
        type: 'alert',
        icon: 'fa fa-exclamation-circle',
        header: 'Network Issue',
        buttonsAlign: 'horizantal',
        label: 'Check your network connection',
        yesBtn: 'OK',
      },
    });
  }

  closeConnectionDialog(): void {
    this.dialogRef.close();
  }
}
