import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';


@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
  @Input() doChartData: any = [];
  @Input() width = 300;
  @Input() height = 300;
  @Input() margin = 40;
  @Input() isLegend: any;
  @Input() legendWidth = 400;
  @Input() legendcurrentId: any;
  @Input() donutcurrentId: any;
  @Input() days:any;

  svg: any = null;
  legendSvg: any = null;
  donutChartData: any;
  values: any;
  keys:any;
  donutMarginTop:any;

  radius: any = Math.min(this.width, this.height) / 2 - this.margin;

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.doChartData && Object.keys(this.doChartData).length > 0) {
      this.svg = null;
      d3.select('#' + this.donutcurrentId).select('svg').remove();
      d3.select('#' + this.legendcurrentId).select('svg').remove();
      this.generateLegend();
      this.generateDonutChart();
    }
  }
  ngAfterViewInit(): void {
  
    if (!this.svg?._groups[0][0]) {

      if (this.isLegend) {
        this.generateLegend();
      }
      this.generateDonutChart();
    }
  }
  generateLegend(): void {
    var colors = ['#13B4FF', '#FBCCDC', '#FBC665','#C4A4DC', '#CCCCCC', '#F7F7CC'];
    var side = ['Supine', 'Right', 'Left', 'Prone', 'Out of bed', 'No data'];

    var data = this.doChartData[0];
    this.keys = Object.keys(data);
    this.values = Object.values(data);

    this.legendSvg = d3
      .select('#' + this.legendcurrentId)
      .append('svg')
      .attr('width', this.legendWidth)
      .attr('height', 130)

    const legend = this.legendSvg
      .selectAll('#' + this.legendcurrentId)
      .data(side)
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', (d, i) => `translate(+${this.width / 2 + 20},${i * 20 + this.height / 2 - 150})`);

    legend.append('rect')
      .attr('x', this.width - 170)
      .attr('y', this.height - 297)
      .attr('width', 10)
      .attr('height', 10)
      .style('fill', (d, i) => colors[i % colors.length]);

    legend.append('text')
      .attr('x', this.width - 90)
      .attr('y', 9)
      .attr('dy', '.35em')
      .style('text-anchor', 'end')
      .style('font-size', 15)
      .text((d) => { 
        switch(d) {
            case 'Supine':
                return d;
            case 'Right':
                return d;
            case 'Left':
                return d;
            case 'Prone':
                return d;
            case 'Out of bed':
                return d;
            case 'No data':
                return d;
            default:
                return d;
        }
    })
    .attr('transform', (d) => {
        switch(d) {
            case 'Supine':
                return 'translate(-17, -1)';
            case 'Right':
                return 'translate(-27, -1)';
            case 'Left':
                return 'translate(-35, -1)';
            case 'Prone':
                return 'translate(-21, -1)';
            case 'Out of bed':
                return 'translate(8, -1)';
            case 'No data':
                return 'translate(-7, -1)';
            default:
                return 'translate(0, 0)';
        }
    });
  }

  generateDonutChart(): void {
    const colors = {
      "Supine": '#13B4FF',
      "Right": '#FBCCDC',
      "Left": '#FBC665',
      "Prone": '#C4A4DC',
      "Out of bed": '#CCCCCC',
      "No data": '#F7F7CC'
    };
    var svg = d3.select('#' + this.donutcurrentId)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", "translate(" + this.width / 2 + "," + this.height / 2  + ")");

    const pie = this.generatePie();

    const donutData = pie(this.values);

    var arc = d3.arc()
      .innerRadius(this.radius * 0.5)
      .outerRadius(this.radius * 0.8)

    svg.selectAll('#' + this.donutcurrentId)
      .data(donutData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => colors[this.keys[i]]);
  }

  generatePie(): any {
    return d3Shape.pie().value((d) => d as number);
  }
  
  onResized(event: ResizedEvent): void {
    if (this.doChartData && Object.keys(this.doChartData).length > 0) {
      this.svg = null;
      d3.select('#' + this.donutcurrentId).select('svg').remove();
      this.generateDonutChart();
    }
  }
}
