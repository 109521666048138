import { Component, Input } from '@angular/core';
import { RiskStep } from 'src/app/models/riskAssessmentModels';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-lenexa-stepper',
  templateUrl: './lenexa-stepper.component.html',
  styleUrls: ['./lenexa-stepper.component.scss'],
})
export class LenexaStepperComponent extends BaseComponent {
  @Input() steps: RiskStep[] = [];

  constructor() {
    super();
  }
}
