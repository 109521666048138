import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackdropService {
  public backDropSubject = new BehaviorSubject<boolean>(false);

  constructor() {}

  getBackDropState(): Observable<boolean> {
    return this.backDropSubject.asObservable();
  }

  setBackDropState(value: boolean): void {
    this.backDropSubject.next(value);
  }
}
