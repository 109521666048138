<div fxFlex="100" class="dashboard-wrapper">
    <div fxFlex="20" fxFlex.xs="" fxFlex.sm="" fxFlex.md="" fxLayout="column" style="margin-top: 7px;">
        <div class="sidebar">
            <a [ngClass]="{ 'active': isThreshold }" (click)="Threshold()">Threshold Values</a>
            <a [ngClass]="{ 'active': isDataBox }" (click)="dataBox()">DataBox Details</a>
            <a [ngClass]="{ 'active': isRefreshFrequency }" (click)="refreshFrequencyData()">Refresh Frequency</a>
            <a [ngClass]="{ 'active': isReportGeneration }" (click)="reportGeneration()">Report Generation</a>
        </div>
    </div>
    <div *ngIf="isThreshold" fxFlex="80" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100">
        <form [formGroup]="thresholdPatient">
            <mat-card *ngIf="showPatientSelection" class="data-box-threshold">
                <div fxFlexLayout="center center" class = "threshold-patientSelect">
                    <p fxFlex="16">Patient Number:</p>
                    <mat-form-field class="matpatientSelect" floatLabel="never">
                        <mat-label *ngIf="!isValueSelected" >Select Patient
                            Number</mat-label>
                        <mat-select style="margin-top: 0px;" formControlName="seletPatient"
                            (selectionChange)="selectPatient($event.value)" (opened)="onSelectDropdownOpened()"
                            (closed)="isValueSelected = false">
                            <mat-option *ngFor="let email of patientData" [value]="email">{{email}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <button name="confirm" class="submit" (click)="patientConfigValue()">
                        Submit
                    </button>
                    <button name="confirm" class="clear" (click)="closePatient()">
                        Clear
                    </button>
                </div>
            </mat-card>
        </form>
        <form *ngIf="!showPatientSelection" [formGroup]="addThresholdValues">
            <div *ngIf="dataLoader" class="data-spinner">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
            <mat-card class="data-box-card">
                <div *ngFor="let item of patientConfigValues; let i = index">
                    <div *ngIf="dataLoader" class="data-load">
                        <mat-spinner [diameter]="50"></mat-spinner>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Patient Number:</label>
                            <input fxFlex="30" placeholder="Enter Patient Number" type="text"
                                [(ngModel)]="item.patient_number" formControlName="patientNumber" name="patientNumber"
                                disabled (change)="handleInputChange('patientNumber', item.patient_number)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Buffer Time:</label>
                            <input fxFlex="30" placeholder="Enter Buffer Time" type="text"
                                [(ngModel)]="item.buffer_time" formControlName="bufferTime" name="bufferTime"
                                (change)="handleInputChange('bufferTime', item.buffer_time)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Start Threshold:</label>
                            <input fxFlex="30" placeholder="Enter Threshold" type="text"
                                [(ngModel)]="item.threshold" formControlName="threshold" name="threshold"
                                (change)="handleInputChange('threshold', item.threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Left Mid Risk Threshold:</label>
                            <input fxFlex="30" placeholder="Enter Left Mid Risk" type="text"
                                [(ngModel)]="item.left_mid_risk_threshold" formControlName="leftMidRisk"
                                name="leftMidRisk"
                                (change)="handleInputChange('leftMidRisk', item.left_mid_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Left High Risk Threshold:</label>
                            <input fxFlex="30" placeholder="Enter Left High Risk" type="text"
                                [(ngModel)]="item.left_high_risk_threshold" formControlName="leftHighRisk"
                                name="leftHighRisk"
                                (change)="handleInputChange('leftHighRisk', item.left_high_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Right Mid Risk Threshold:</label>
                            <input fxFlex="30" placeholder="Enter Right Mid Risk" type="text"
                                [(ngModel)]="item.right_mid_risk_threshold" formControlName="rightMidRisk"
                                name="rightMidRisk"
                                (change)="handleInputChange('rightMidRisk', item.right_mid_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Right High Risk Threshold</label>
                            <input fxFlex="30" placeholder="Enter Right High Risk" type="text"
                                [(ngModel)]="item.right_high_risk_threshold" formControlName="rightHighRisk"
                                name="rightHighRisk"
                                (change)="handleInputChange('rightHighRisk', item.right_high_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Prone Mid Risk Threshold</label>
                            <input fxFlex="30" placeholder="Enter Prone Mid Risk" type="text"
                                [(ngModel)]="item.prone_mid_risk_threshold " formControlName="proneMidRisk"
                                name="proneMidRisk"
                                (change)="handleInputChange('proneMidRisk', item.prone_mid_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Prone High Risk Threshold</label>
                            <input fxFlex="30" placeholder="Enter Prone High Risk" type="text"
                                [(ngModel)]="item.prone_high_risk_threshold " formControlName="proneHighRisk"
                                name="proneHighRisk"
                                (change)="handleInputChange('proneHighRisk', item.prone_high_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Supine Mid Risk Threshold</label>
                            <input fxFlex="30" placeholder="Enter Supine Mid Risk" type="text"
                                [(ngModel)]="item.supine_mid_risk_threshold" formControlName="supineMidRisk"
                                name="supineMidRisk"
                                (change)="handleInputChange('supineMidRisk', item.supine_mid_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Supine High Risk Threshold</label>
                            <input fxFlex="30" placeholder="Enter Supine High Risk" type="text"
                                [(ngModel)]="item.supine_high_risk_threshold" formControlName="supineHighRisk"
                                name="supineHighRisk"
                                (change)="handleInputChange('supineHighRisk', item.supine_high_risk_threshold)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Alert Frequency</label>
                            <input fxFlex="30" placeholder="Enter Alert Frequency" type="text"
                                [(ngModel)]="item.alert_frequency" formControlName="alertFrequency"
                                name="alertFrequency"
                                (change)="handleInputChange('alertFrequency', item.alert_frequency)" />
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="patientnumberValue">
                            <label fxFlex="30">Prefered Patient Name:</label>
                            <select style="height: 42px;" fxFlex="30" (change)="selectPatientName($event.target.value)">
                                <option *ngFor="let pName of patientname" [value]="pName.name"
                                    [selected]="pName.name === item.prefered_patientname">{{pName.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="timezoneValue">
                            <label fxFlex="30">Time Zone:</label>
                            <mat-form-field fxFlex="30">
                                <mat-select class="panel-select" [value]="item.time_zone"
                                    (selectionChange)="SelectTimeZone($event)" (closed)="onSelectMenuClosed()">
                                    <input matInput [value]="item.time_zone" class="input-panel"
                                        (input)="filterTimeZones($event.target.value)" [(ngModel)]="searchKeyword"
                                        formControlName="searchKeyword" (keyup.backspace)="onInputChange()">
                                    <mat-option *ngFor="let tzone of selectedTZone" [value]="tzone">
                                        {{ tzone }}
                                    </mat-option>
                                    <mat-option *ngIf="selectedTZone.length >= 0" disabled>No time zones
                                        found</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('24h_report_email_list')">
                                {{ showEmailLists['24h_report_email_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">24H Report Email List:</label>
                            <div *ngIf="showEmailLists['24h_report_email_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['24h_report_email_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter 24HR Email" type="text" name="reportEmailList"
                                        [value]="patientConfigValues[0]['24h_report_email_list'][j]"
                                        (change)="handleInputChange('24h_report_email_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('24h_report_email_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton"
                                    (click)="addEmailField('24h_report_email_list')">+ Add</button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('24h_report_sms_list')">
                                {{ showEmailLists['24h_report_sms_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">24H Report SMS List:</label>
                            <div *ngIf="showEmailLists['24h_report_sms_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['24h_report_sms_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter Same Pose Email" type="text" name="reportEmailList"
                                        [value]="patientConfigValues[0]['24h_report_sms_list'][j]"
                                        (change)="handleInputChange('24h_report_sms_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('24h_report_sms_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton" (click)="addEmailField('24h_report_sms_list')">+
                                    Add</button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('same_pose_alert_email_list')">
                                {{ showEmailLists['same_pose_alert_email_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">Same Pose Alert Email List:</label>
                            <div *ngIf="showEmailLists['same_pose_alert_email_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['same_pose_alert_email_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter Same Pose Email" type="text" name="samePoseEmail"
                                        [value]="patientConfigValues[0]['same_pose_alert_email_list'][j]"
                                        (change)="handleInputChange('same_pose_alert_email_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('same_pose_alert_email_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton"
                                    (click)="addEmailField('same_pose_alert_email_list')">+ Add</button>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('same_pose_alert_sms_list')">
                                {{ showEmailLists['same_pose_alert_sms_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">Same Pose Alert SMS List:</label>
                            <div *ngIf="showEmailLists['same_pose_alert_sms_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['same_pose_alert_sms_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter Same Pose SMS" type="text" name="samePoseSMS"
                                        [value]="patientConfigValues[0]['same_pose_alert_sms_list'][j]"
                                        (change)="handleInputChange('same_pose_alert_sms_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('same_pose_alert_sms_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton"
                                    (click)="addEmailField('same_pose_alert_sms_list')">+ Add</button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('no_data_alert_email_list')">
                                {{ showEmailLists['no_data_alert_email_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">No Data Alert Email List:</label>
                            <div *ngIf="showEmailLists['no_data_alert_email_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['no_data_alert_email_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter No Data Email" type="text" name="noDataEmail"
                                        [value]="patientConfigValues[0]['no_data_alert_email_list'][j]"
                                        (change)="handleInputChange('no_data_alert_email_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('no_data_alert_email_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton"
                                    (click)="addEmailField('no_data_alert_email_list')">+ Add</button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('no_data_alert_sms_list')">
                                {{ showEmailLists['no_data_alert_sms_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">No Data Alert SMS List:</label>
                            <div *ngIf="showEmailLists['no_data_alert_sms_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['no_data_alert_sms_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter No Data SMS" type="text" name="noDataSMS"
                                        [value]="patientConfigValues[0]['no_data_alert_sms_list'][j]"
                                        (change)="handleInputChange('no_data_alert_sms_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('no_data_alert_sms_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton"
                                    (click)="addEmailField('no_data_alert_sms_list')">+ Add</button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('weekly_report_email_list')">
                                {{ showEmailLists['weekly_report_email_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">weekly Report Email List:</label>
                            <div *ngIf="showEmailLists['weekly_report_email_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['weekly_report_email_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter Weekly report Email" type="text" name="weeklyReportEmail"
                                        [value]="patientConfigValues[0]['weekly_report_email_list'][j]"
                                        (change)="handleInputChange('weekly_report_email_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('weekly_report_email_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton"
                                    (click)="addEmailField('weekly_report_email_list')">+ Add</button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="emailList">
                            <button class="displayData" (click)="toggleEmailList('weekly_report_sms_list')">
                                {{ showEmailLists['weekly_report_sms_list'] ? '-' : '+'}}
                            </button>
                            <label fxFlex="30">weekly Report SMS List:</label>
                            <div *ngIf="showEmailLists['weekly_report_sms_list']" class="threshold-emailsList">
                                <div *ngFor="let email of patientConfigValues[0]['weekly_report_sms_list']; let j = index">
                                    <input fxFlex="100" placeholder="Enter Weekly Report SMS" type="text" name="weeklyReportSMS"
                                        [value]="patientConfigValues[0]['weekly_report_sms_list'][j]"
                                        (change)="handleInputChange('weekly_report_sms_list', $event.target.value)" />
                                    <button type="button" class="removeButton"
                                        (click)="removeEmailField('weekly_report_sms_list', j)">Remove</button>
                                </div>
                                <button type="button" class="addButton"
                                    (click)="addEmailField('weekly_report_sms_list')"> + Add</button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" class="pad-left-20">
                        <button name="confirm" class="update" (click)="updateData()">
                            Update
                        </button>
                        <button name="confirm" class="cancel" (click)="clearForm()">
                            Cancel
                        </button>
                    </div>
                </div>
            </mat-card>
        </form>
    </div>
    <div *ngIf="isDataBox" fxFlex="80" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100">
        <form [formGroup]="addThresholdValues">
            <mat-card class="dataBoxDetails">
                <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                    <p fxFlex="30">Please enter New Hub Details Here:</p>
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="newHubId">
                        <label fxFlex="7">Hub ID :</label>
                        <input fxFlex="30" placeholder="Enter Hub ID"
                            type="text" [(ngModel)]="HubID" formControlName="HubID" name="HubID" maxlength="4" />
                    </div>

                    <div fxLayout="row">
                        <button name="confirm" class="submitHub" (click)="dataBoxHub()" [disabled]="HubID.length !== 4">
                            Submit
                        </button>
                        <button name="confirm" class="clearHub" (click)="clearHubDetail()">
                            Clear
                        </button>
                    </div>
                </div>
            </mat-card>
        </form>
    </div>
    <div *ngIf="isRefreshFrequency" fxFlex="80" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100">
        <form [formGroup]="addThresholdValues">
            <mat-card class="refreshFreq">
                <div fxLayout="row wrap" fxLayoutGap="16px grid" class="pad-10">
                    <p fxFlex="30">Please Update Here For Refresh Data:</p>
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="refreshValue">
                        <label fxFlex="21">Refresh Frequency(In Min):</label>
                        <input fxFlex="25" placeholder="Update Refresh Timer Frequency" type="number" [(ngModel)]="rTime"
                            formControlName="rTime" name="rTime" min="0" />
                    </div>
                    <div fxLayout="row">
                        <button name="confirm" class="refreshData" (click)="refreshFreqData()">
                            Submit
                        </button>
                        <button name="confirm" class="clearRef" (click)="clearRefreshDetail()">
                            Clear
                        </button>
                    </div>
                </div>
            </mat-card>
        </form>
    </div>
    <div *ngIf="isReportGeneration" fxFlex="80" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100">
        <div *ngIf="!dataLoader" class="pdf-spinner">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div fxLayout="column" fxFlexLayout="center center" class="pdf-selection">
            <form [formGroup]="thresholdPatient">
                <div class="databoxValue">
                    <p fxFlex="16">Patient Number:</p>
                    <mat-form-field class="pdfMatForm" floatLabel="never">
                        <mat-label *ngIf="!isValueSelected">Select Patient
                            Number</mat-label>
                        <mat-select style="margin-top: -1vh;" formControlName="seletPatient"
                            (selectionChange)="onSelect($event.value)" (opened)="onSelectDropdownOpened()"
                            (closed)="isValueSelected = false">
                            <mat-option *ngFor="let email of patientData" [value]="email">{{email}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div class="pdfDateSelect">
                <label fxFlex="29">Start Date:</label>
                <input placeholder="Choose start-date" [(ngModel)]="dateTime_start" [owlDateTime]="dt1"
                    class="owl-date-picker" [owlDateTimeTrigger]="dt1" [max]="futureDate">
                <owl-date-time #dt1></owl-date-time>

            </div>
            <div class="pdfDateSelect">
                <label fxFlex="29">End Date: </label>
                <input placeholder="Choose end-date" [(ngModel)]="dateTime_end" [owlDateTime]="dt2"
                    class="owl-date-picker" [owlDateTimeTrigger]="dt2" [max]="futureDate">
                <owl-date-time #dt2></owl-date-time>

            </div>
            <div fxLayout="row" style="margin-top: 30px;">
                <button name="confirm" class="reportData" (click)="validationReport()">
                    Submit
                </button>
                <button name="confirm" class="clearRef" (click)="clearReportData()">
                    Clear
                </button>
            </div>
        </div>
    </div>
</div>
<div class="pdf-report">
    <div class="report-container" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayoutAlign="center center">
        <div id="contentToConvert">
            <div class="form-group custom pdf-card">
                <div fxLayout="row wrap" class="pad-20">
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%">
                        <p class="lenexa-heading">{{graphId}} Position Report: {{dateRange}}</p>
                    </div>
                </div>
                <div style="width: 100%;">
                    <app-timeline-data *ngIf="timelineGraphData" [dataToDisplay]="timelineGraphData"
                        [timelineId]="patient_number" [graphId]="timeGraphId"></app-timeline-data>
                </div>
                <div fxLayout="row" class="pad-top-10">
                    <div fxLayout="column" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="donutData" [class.days-1]="donutDays === 1"
                        [class.days-2]="donutDays === 2" [class.days-3]="donutDays === 3" [class.days-4]="donutDays === 4"
                        [class.days-5]="donutDays === 5" [class.days-6]="donutDays === 6" [class.days-7]="donutDays === 7">
                        <p> {{peferredPositon}} The position breakdown was:</p>
                        <div class="pieChartData">
                            <div class="positionPercentage">
                                <div *ngFor="let item of percentageData | keyvalue" class="keyValueData">
                                    <span>'{{item.key}}': {{item.value | number:'1.2-2'}}%</span>
                                </div>
                            </div>
                            <div class="pieChartForm">
                                <div class="pieDirection">
                                    <p>Position Breakdown</p>
                                    <app-donut-chart *ngIf="chartData" [doChartData]="chartData" [days]="donutDays"
                                        [height]="300" [width]="300" [margin]="40" [isLegend]="true" [legendWidth]="540"
                                        [legendcurrentId]="'legend'" [donutcurrentId]="'donut'"></app-donut-chart>
                                </div>
                            </div>
                        </div>
                        <div class="paraData">
                        <p>And {{longestStay}}.</p></div>
                    </div>
                </div>
            </div>
        </div>
        <input type="button" value="Convert" (click)="convertToPDF()" />
    </div>
</div>