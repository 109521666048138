<div class="loader-component">
  <div class="image-container" fxLayout="column" fxLayoutFill fxLayoutAlign="center center">
    <div fxLayout="row">
      <img class="loader-arrow" src="./assets/images/loader-arrow.png">
      <img class="cross-img cross" src="./assets/images/loader-cross.png">
      <img class="lenexa-text" src="./assets/images/loader-text.png">
    </div>
    <div class="loading-text" fxLayout="column">
      <div>{{'loader.title'| translate}}</div>
      <div class="inner-text">{{'loader.text' | translate}}</div>
    </div>
  </div>
</div>