<div class="lenexa-container">
    <div class="loader-container" *ngIf="isloading && isHome">
        <app-loader></app-loader>
    </div>
    <div class="app-container" *ngIf="!isloading" fxLayout="column">
        
        <app-header class="app-header"></app-header>
        <div class="content-container" fxLayout="row" fxLayoutFill fxLayoutAlign="center" style="margin-top: -12px;">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="back-drop" *ngIf="isBackDropRequied"></div>
</div>