import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AddPatientComponent,
  DashboardComponent,
  LenexaSheetConnectorComponent,
  LoginComponent,
  PdfComponent,
} from './components';


import { AuthGuard } from './services';
import { DataBoxKinesisConfigurationComponent } from './components/data-box-kinesis-configuration/data-box-kinesis-configuration.component';
import { OrganizationRegisterComponent } from './components/organization-register/organization-register.component';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';



const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: ':subdomain/dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addpatient',
    component: AddPatientComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'connector',
    component: LenexaSheetConnectorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pdf',
    component: PdfComponent,
  },
  {
    path: 'admin',
    component: DataBoxKinesisConfigurationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organizationDetails',
    component:OrganizationRegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'organization',
    component:OrganizationListComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
