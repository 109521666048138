<div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div class="add-container" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%"  fxLayoutAlign="center center">
        <form [formGroup]="addPatientForm" (submit)="saveBed();">
            <mat-card class="form-group custom add-patient-card">
                <div fxLayout="row wrap">
                    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%">
                        <p class="lenexa-heading">ADD WARD-BED DETAILS</p>
                        <hr>
                    </div>
                </div>
                <mat-card-content>
                    <div class="add-patient-container">
                        <div fxLayout="row wrap" fxLayoutGap="16px grid">
                            <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="50%">
                                <label class="add-patient-heading">Add Ward-Bed Number:</label>
                                <div fxLayout="column">
                                    <div class="bed-container"
                                        [ngClass]="{ 'error-passcode': ispatientExists || severError }">
                                        <input type="text" value="WARD" disabled style="max-width:40px">
                                        <input type="text" [(ngModel)]="wardNumber" formControlName="wardNumber"
                                            name="wardNumber" id="wardNumber" (keyup)="onChangeBed()" maxlength="20"
                                            placeholder="Enter Ward Number">

                                        <input type="text" value="Bed" disabled style="max-width:30px">
                                        <input type="text" [(ngModel)]="bednumber" formControlName="bednumber"
                                            name="bednumber" id="bednumber" (keyup)="onChangeBed()" maxlength="20"
                                            placeholder="Enter Bed Number">
                                        <div *ngIf="hasError"></div>
                                    </div>
                                </div>
                                <div class="error-message" *ngIf="ispatientExists || severError">{{severErrorMessage}}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <div fxLayout="row"  fxLayoutAlign="center center">
                    <mat-card-actions fxLayout="column" class="w-50">
                        <button type="submit" name="save" mat-button class="lenexa-btn lenexa-btn-primary pointer"
                            style="min-width: 10vw; height: 38px; border-radius: 20px;" [disabled]="submitted"> Submit
                        </button>
                    </mat-card-actions>
                </div>
            </mat-card>
        </form>
    </div>
</div>