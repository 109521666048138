import { Component, OnInit,Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientService } from 'src/app/services';

@Component({
  selector: 'app-patient-databox-list',
  templateUrl: './patient-databox-list.component.html',
  styleUrls: ['./patient-databox-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientDataboxListComponent implements OnInit {
  selectedCheckboxes: string[] = [];
  selectedHubIdObj : any;
  constructor(public dialogRef: MatDialogRef<PatientDataboxListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private sharedData: PatientService) {}

  ngOnInit(): void {
  }
  onSubmit(data): void {
    this.dialogRef.close(data);
  }
  toggleCheckbox(event: any,hubId:any) {
    if (event.target.checked) {
      this.selectedCheckboxes.push(hubId);
    } else {
      this.selectedCheckboxes = this.selectedCheckboxes.filter(item => item !== hubId);
    }
    this.selectedHubIdObj = { hub_ids:this.selectedCheckboxes };
    this.sharedData.setSelectedHubIds(this.selectedHubIdObj);
  }
  isUnpairButtonDisabled(): boolean {
    return this.selectedCheckboxes.length === 0;
  }
}
