<div fxLayout="column" fxLayoutAlign="center center"
  [ngClass]="{'alert-dialog':(data?.type == 'alert'),'fault-dialog':(data?.type == 'fault')}">
  <div class="dialog-heading">
    <span class="dialog-header" [ngClass]="{'text-center':data.headerImg}"><i class="{{data.icon}} icon"
        *ngIf="data.icon"></i><img class="header-image" (click)="onSubmit('no')" src="{{data.headerImg}}"
        *ngIf="data.headerImg" alt="image">{{data.header}}</span>
  </div>
  <mat-dialog-content fxLayout="column" class="dialog-content">
    <span [innerHtml]="data.label | translate"></span>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="column" class="w-100" *ngIf="data.buttonsAlign !== 'horizantal'">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial
      class="lenexa-btn lenexa-btn-primary w-100 margin-top-30" (click)="onSubmit('no')"> {{data.yesBtn |
      translate}}</button>
    <button mat-button mat-dialog-close class="lenexa-btn lenexa-btn-secondary w-100 margin-top-10 margin-bottom-30"
      (click)="onSubmit('yes')">{{data.cancelBtn |translate}}</button>
  </mat-dialog-actions>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="w-100 dialog-buttons-horizantal"
    *ngIf="data.buttonsAlign === 'horizantal'">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="lenexa-btn lenexa-btn-primary"
      (click)="onSubmit('yes')"> {{data.yesBtn | translate}}</button>
    <button mat-button mat-dialog-close class="lenexa-btn lenexa-btn-secondary" *ngIf="data.cancelBtn"
      (click)="onSubmit('no')">{{data.cancelBtn |translate}}</button>
  </mat-dialog-actions>
</div>