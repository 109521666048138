import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, interval, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleStateService {

  private activitySubject = new Subject<void>();
  private idleTimer: any; 
  private idleTime = 30 * 60 * 1000; //converting minutes into milliseconds.

  constructor() {
    this.resetIdleTimer();
    this.activitySubject.subscribe(() => this.resetIdleTimer());

    //window.addEventListener('mousemove', () => this.userActivity());
    window.addEventListener('click',() => this.userActivity());
  }

  private resetIdleTimer() {
    clearInterval(this.idleTimer); 
    this.idleTimer = setInterval(() => {
      this.activitySubject.next(); 
    }, this.idleTime);

    this.activitySubject.pipe(debounceTime(this.idleTime)).subscribe(() => {
      clearInterval(this.idleTimer);
    });
  }

  userActivity() {
    this.activitySubject.next();
  }

  getIdleTimer(): Observable<any> {
    return this.activitySubject.asObservable();
  }
}
