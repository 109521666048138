import { Component, OnInit, Inject } from '@angular/core';
import { LoginService, AuthService, BaseService, PatientService } from '../../services';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  password: any;
  userName: any;
  Password: any;
  errorMessage = false;
  passcode: any;
  navigationUrl: string;
  LoginForm: FormGroup;
  token: any;
  hide: boolean = true;
  refreshToken: any;
  dataLoader: boolean = false;
  constructor(
    public loginService: LoginService,
    public router: Router,
    public auth: AuthService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoginComponent>,
    public bs: BaseService,
    private patientService: PatientService
  ) {
    this.LoginForm = this.formBuilder.group({
      userName: [null, Validators.required],
      Password: [null, Validators.required],
    });
  }

  ngOnInit(): void {
  }
  passcodeLogin(): void {
    let loginDetails = {
      "username": this.userName,
      "password": this.Password
    }
    this.dataLoader = true;
    this.loginService.login(loginDetails).subscribe(
      (res: any) => {
        this.token = res.access_token;
        this.refreshToken = res.refresh_token;
        localStorage.setItem("refreshtoken", this.refreshToken);
        this.bs.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + res.access_token,
          }),
        };
        let role = res.user_role;
        localStorage.setItem('user',role);
        localStorage.setItem('api_url',res.base_url);

        if (res.user_role === 'normal') {
          let subdomain = res.OrganizationDomainName;
          localStorage.setItem('base_org', subdomain);
          this.navigationUrl = `/${subdomain}/dashboard`;
          this.router.navigateByUrl(this.navigationUrl);
          this.auth.setToken(this.token);
        } else if (res.user_role === 'admin') {
          let subdomain = res.OrganizationDomainName;
          localStorage.setItem('base_org', subdomain);
          this.navigationUrl = `/${subdomain}/dashboard`;
          this.router.navigateByUrl(this.navigationUrl);
          this.auth.setToken(this.token);
        } else if (res.user_role === 'superadmin') {
          this.router.navigate(['organization'])
          this.auth.setToken(this.token);
        }
        this.dataLoader = false;
      },
      (error) => {
        this.errorMessage = true;
        this.dataLoader = false;
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close('cancel');
  }

  handleChange(): void {
    if (
      this.userName !== '' ||
      this.Password != ''
    ) {
      this.errorMessage = false;
    }
  }
  togglePasswordVisibility() {
    this.hide = !this.hide;
  }
}
