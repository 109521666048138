<div class="organization-wrapper">
    <form [formGroup]="organizationList">
        <mat-card fxLayoutAlign="center center" class="orgList">
            <div fxLayout="column" fxLayoutAlign="center center" class="OrganSelect">
                <label>Please Select Organization</label>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="16px grid" class="organization-databox">
                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="50%" class="organization-data">
                    <div fxFlexLayout="center center" class="organizationCreate">
                        <mat-form-field class="matOrganization" floatLabel="never">
                            <mat-label *ngIf="!isValueSelected">Select organization</mat-label>
                            <mat-select formControlName="organization" (selectionChange)="selectOrg($event.value)"
                                (opened)="onSelectDropdownOpened()" (closed)="isValueSelected = false">
                                <mat-option *ngFor="let oName of adminOrg" [value]="oName">{{oName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="organizationButton">
                <button name="confirm" class="submit" (click)="adminOrganization()">
                    Submit
                </button>
                <button name="confirm" class="clear" (click)="clearOrganization()">
                    Clear
                </button>
            </div>
        </mat-card>
        <a class="need-help" (click)="newOrganization()">+Add New Organization</a>
    </form>
</div>