import { Component, OnInit, HostBinding } from '@angular/core';
import {
  trigger,
  style,
  transition,
  animate,
  query,
  stagger,
} from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('loaderAnimation', [
      transition(':enter', [
        query('.cross, .loader-arrow, .lenexa-text, .loading-text', [
          style({ opacity: 0 }),
          stagger('1s', [animate('500ms', style({ opacity: 1 }))]),
        ]),
      ]),
    ]),
  ],
})
export class LoaderComponent implements OnInit {
  @HostBinding('@loaderAnimation')
  ngOnInit(): void {}
}
