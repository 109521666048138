import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService, BaseService, LoginService, PatientService } from 'src/app/services';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {
  organizationList: any;
  selectedOrg: any;
  adminOrg: any;
  subdomain: any;
  seledtedOrganization: any;
  navigationUrl: string;
  isValueSelected: boolean = false;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems: number;
  loading = false;

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private token = JSON.parse(localStorage.getItem('token'));
  @ViewChild(MatSelect) select: MatSelect;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    public auth: AuthService,
    private toaster: ToastrService,
    private baseService: BaseService,
    public loginService: LoginService) {

    this.organizationList = this.formBuilder.group({
      organization: [''],
    });
    if (this.token) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token.access_token,
        }),
      };
    }
  }

  ngOnInit(): void {
    this.OrganizationList();
    this.subdomain = localStorage.getItem('base_org');
  }
  selectOrg(organ: any): void {
    this.isValueSelected = true;
    this.organizationList.get('organization').setValue(organ);
    this.seledtedOrganization = organ;
  }

  organizationRedirect(): Observable<any> {
    return this.baseService.get(`https://yeh7u2wxha.execute-api.ap-southeast-2.amazonaws.com/Organization/${this.seledtedOrganization}`);
  }

  OrganizationList(): void {
    if (this.auth.isTokenValid()) {
      let params = new HttpParams()
        .set('pageNumber', this.currentPage.toString())
        .set('pageSize', this.itemsPerPage.toString());
      this.loginService.organizationData(params).subscribe((res) => {
        this.totalItems = res.totalPages;
        this.adminOrg = res.data;
      });
    } else {
      this.auth.refreshToken();
    }
  }
  onSelectDropdownOpened(): void {
    if (this.auth.isTokenValid()) {
      this.select.panel.nativeElement.addEventListener('scroll', () => {
        if (this.shouldLoadNextPage()) {
          this.loadNextPage();
        }
      });
      window.addEventListener('resize', () => {
        if (this.shouldLoadNextPage()) {
          this.loadNextPage();
        }
      });

      this.checkPagination();
    } else {
      this.auth.refreshToken();
    }
  }
  checkPagination(): void {
    if (this.shouldLoadNextPage()) {
      this.loadNextPage();
    }
  }

  shouldLoadNextPage(): boolean {
    if (!this.loading && this.currentPage < this.totalItems) {
      const panel = this.select.panel.nativeElement;
      return panel.scrollHeight - panel.scrollTop <= panel.clientHeight + 1;
    }
    return false;
  }

  loadNextPage(): void {
    this.loading = true;
    this.currentPage++;
    let params = new HttpParams()
      .set('pageNumber', this.currentPage.toString())
      .set('pageSize', this.itemsPerPage.toString());
    this.loginService.organizationData(params).subscribe((res) => {
      this.adminOrg = this.adminOrg.concat(res.data);
      this.loading = false;
    });
  }
  adminOrganization(): void {
    if (this.auth.isTokenValid()) {
      if (this.seledtedOrganization) {
        this.organizationRedirect().subscribe((r) => {
          if (r.status == 'success' && r.base_url != null) {
            localStorage.setItem('api_url', r.base_url);
            localStorage.setItem('base_org', r.OrganizationDomainName);
            this.navigationUrl = `/${r.OrganizationDomainName}/dashboard`;
            this.router.navigateByUrl(this.navigationUrl);
          }
          else {
            this.toaster.error('Configuration setup is not ready');
          }
        });
      }
    } else {
      this.auth.refreshToken();
    }
  }
  newOrganization(): void {
    this.router.navigate(['organizationDetails'])
  }
  clearOrganization(): void {
    this.organizationList.get('organization').setValue('');
    this.isValueSelected = false;
  }
}
