import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pairing-dailog',
  templateUrl: './pairing-dailog.component.html',
  styleUrls: ['./pairing-dailog.component.scss']
})
export class PairingDailogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<PairingDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  onSubmit(data): void {
    this.dialogRef.close(data);
  }

}
