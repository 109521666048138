<div fxLayout="row wrap" fxLayoutGap="16px grid">
  <div class="main-container" [ngClass]="{'dialog-main-container': data.isDialog }" fxFlex="100%" fxFlex.xs="100%"
    fxFlex.sm="100%">
    <mat-card class="pad-30" class="passcode-container" [ngClass]="{'dialog-passcode-container': data.isDialog }">
      <div *ngIf="dataLoader" class="data-spinner">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <mat-card-content>
        <img *ngIf="data.isDialog" class="close-button" src="./assets/images/close_icon.png" height="30" width="30"
          alt="closeButton" (click)="closeDialog()" />
        <div fxLayout="column" fxLayoutAlign="center center" class="pad-top-30">
          <div>
            <p class="passcode-heading">{{ "login.title" | translate }}</p>
          </div>
          <!-- <div class="passcode-subheading">{{ "login.text" | translate }}</div> -->
        </div>
        <div fxLayout="row wrap" class="passcode-input-container">
          <form [formGroup]="LoginForm" class="passcode" [ngClass]="{ 'error-passcode': errorMessage }">
            <div fxLayout="column" fxLayoutAlign="center">
              <div class="login-container">
                <input type="text" placeholder="Username" [(ngModel)]="userName"
                  formControlName="userName" name="userName" (change)="handleChange()" />
              </div>
              <div class="login-container">
                <input  [type]="hide ? 'password' : 'text'" placeholder="Password"  [ngClass]="{'password-visible': !hide}" 
                  [(ngModel)]="Password" formControlName="Password" name="Password" (change)="handleChange()" />
                <i class='fa' [ngClass]="{'fa-eye-slash': hide, 'fa-eye': !hide}"
                  (click)="togglePasswordVisibility()"></i>
              </div>
            </div>
          </form>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="errorMessage" class="pad-top-30 error-message">
          {{ "login.errormessage" | translate }}
        </div>
      </mat-card-content>
      <div fxLayout="row" class="login-btn">
        <mat-card-actions fxLayout="column" fxLayoutAlign="center center" class="w-100 actions-container">
          <button mat-button style="width: 50%;height: 40px;" class="lenexa-btn lenexa-btn-primary"
            (click)="passcodeLogin()" [disabled]="!userName || !Password">
            {{ "login.login" | translate }}
          </button>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>
</div>